import { assignOnly, oneline, spliceOutOf } from "../utils";
import { Answer, GeneralAnswer, NonconformingValueKind, SingleValuedAnswer } from "./answers";
import { IndexedString, ValueAtom } from "./core-data-types";
import {
  NonconformingResponseChoice,
  QuestionDefinition,
  ResponseChoice,
} from "./questions";

/**
 * Function to evaluate if a specific choice should be displayed as selected,
 * based on whether that choice is already a part of the answers.
 **/
export function isSelected(
  choiceIndex: number,
  questionDefinition: QuestionDefinition,
  answer: Answer<IndexedString>
) {
  if (questionDefinition.isMulti) {
    return !!answer?.values?.find((value) => value.choiceIndex === choiceIndex);
  } else {
    return answer?.value?.choiceIndex === choiceIndex;
  }
}

export function isNonconformingSelected (
  choiceIndex: number,
  questionDefinition: QuestionDefinition,
  answer: Answer<IndexedString>
) {
  return !!answer.nonconformingValues?.find(ncv => ncv.value === questionDefinition.nonconformingResponses?.[choiceIndex]?.value);
}

export interface QuestionLikeChoiceHolder {
  isMulti: boolean;
  choices?: ResponseChoice[];
  nonconformingResponses?: NonconformingResponseChoice[];
}
const UNTOGGLEABLE_NCV_KINDS = [NonconformingValueKind.UserDefined];
export function selectStandardChoice(
  item: ResponseChoice,
  parent: QuestionLikeChoiceHolder,
  answer: Answer<ValueAtom>,
  additionalProps: any = {}
): Answer<ValueAtom> {
  const choiceIndex = Array.isArray(parent.choices)
    ? parent.choices.findIndex((c) => c.value === item.value)
    : -1;
  if (choiceIndex === -1) {
    throw new Error(`Could not find ${item.value} in the choices array!`);
  }
  const ncIndex = Array.isArray(parent.nonconformingResponses)
    ? parent.nonconformingResponses.findIndex((r) => r.value === item.value)
    : -1;
  if (ncIndex > -1) {
    throw new Error(
      `This value is from the nonconforming array, please use selectNonconformingChoice instead! (${item.value})`
    );
  }
  const answerHasNCVs =
    Array.isArray(answer.nonconformingValues) &&
    answer.nonconformingValues.length > 0;
  const preservedNCVs = answerHasNCVs
    ? {
        nonconformingValues: answer
          .nonconformingValues!!.filter((ncv) =>
            UNTOGGLEABLE_NCV_KINDS.includes(ncv.kind)
          )
          .map((ncv) => ({ ...ncv })),
      }
    : {};
  // console.log({preservedNCVs});

  if (!parent.isMulti) {
    // SINGLE-SELECT =============================
    if (answer.isMulti === true) {
      throw new Error(
        `isMulti answer on singular question! ${answer.key}+${
          (parent as any).id
        }`
      );
    }
    if ((answer as SingleValuedAnswer<any>)?.value?.value === item.value) {
      // We deselected a single-select, return null answer
      return {
        isMulti: false,
        value: {
          value: null,
        },
        ...preservedNCVs,
        ...additionalProps,
      };
    } else {
      // We selected a new single-select, return answer with item as value
      return {
        isMulti: false,
        value: {
          value: item.value,
          choiceIndex,
        },
        ...preservedNCVs,
        ...additionalProps,
      };
    }
  } else {
    // MULTI-SELECT =====================================================
    if (answer.isMulti === false) {
      throw new Error(
        `singular answer on isMulti question! ${answer.key}+${
          (parent as any).id
        }`
      );
    }

    const answerIndex =
      answer.values?.findIndex((v) => v.value === item.value) ?? -1;
    const choiceIsAlreadySelected = answerIndex > -1;
    if (item.isExclusionary === true) {
      if (choiceIsAlreadySelected) {
        // We deselected an exclusionary value, so return an empty value list
        return {
          isMulti: true,
          values: [],
          ...preservedNCVs,
          ...additionalProps,
        };
      } else {
        // We selected a new exclusionary, so the value list is just that item
        return {
          isMulti: true,
          values: [
            {
              value: item.value,
              choiceIndex,
            },
          ],
          ...preservedNCVs,
          ...additionalProps,
        };
      }
    } else {
      if (choiceIsAlreadySelected) {
        // We deselected a normal option, splice it out of the values list
        const newValues = answer.values.slice();
        newValues.splice(answerIndex, 1);
        return {
          isMulti: true,
          values: newValues,
          ...preservedNCVs,
          ...additionalProps,
        };
      } else {
        // We selected a new normal option, append it to the values list
        return {
          isMulti: true,
          values: [
            ...(answer.values ?? []),
            { value: item.value, choiceIndex },
          ],
          ...preservedNCVs,
          ...additionalProps,
        };
      }
    }
  }
}

export function selectNonconformingChoice(
  item: ResponseChoice,
  parent: QuestionLikeChoiceHolder,
  answer: Answer<ValueAtom>,
  additionalProps: any = {}
): Answer<ValueAtom> {
  const choiceIndex = Array.isArray(parent.choices)
    ? parent.choices.findIndex((c) => c.value === item.value)
    : -1;
  if (choiceIndex > -1) {
    throw new Error(
      oneline`This value is from the choices array, please use 
              selectStandardChoice instead! (${item.value})`
    );
  }
  const ncIndex = Array.isArray(parent.nonconformingResponses)
    ? parent.nonconformingResponses.findIndex((r) => r.value === item.value)
    : -1;
  if (ncIndex === -1) {
    throw new Error(`Could not find ${item.value} in nonconformingResponses!`);
  }
  if (UNTOGGLEABLE_NCV_KINDS.includes(item.nonconformingKind!!)) {
    throw new Error(
      oneline`Cannot toggle a nonconforming value of type 
              ${item.nonconformingKind}! (value: ${additionalProps?.value})`
    );
  }

  const answerHasNCVs =
    Array.isArray(answer.nonconformingValues) &&
    answer.nonconformingValues.length > 0;
  const preservedNCVArray = answerHasNCVs
    ? answer
        .nonconformingValues!!.filter((ncv) =>
          UNTOGGLEABLE_NCV_KINDS.includes(NonconformingValueKind.UserDefined)
        )
        .map((ncv) => ({ ...ncv }))
    : [];

  // this will be a jumping off point for making these behaviors configurable
  // but for now every NCV is single-select (but still stored in an array)
  const shouldClearRegularValues = true;
  const shouldClearNCVs = true;

  const base: Partial<Answer<ValueAtom>> = parent.isMulti
    ? {
        isMulti: true,
        values: shouldClearRegularValues ? [] : answer.values ?? [],
      }
    : {
        isMulti: false,
        value: shouldClearRegularValues ? { value: null } : answer.value,
      };

  const indexInNCVArray =
    answer.nonconformingValues?.findIndex(
      (ncv) => ncv.value === item.value
    ) ?? -1;
  const alreadySelected = indexInNCVArray > -1;

  if (alreadySelected) {
    return {
      ...base,
      nonconformingValues: [
        ...answer.nonconformingValues!!.slice(0, indexInNCVArray),
        ...answer.nonconformingValues!!.slice(indexInNCVArray + 1),
      ],
      ...additionalProps,
    };
  } else {
    return {
      ...base,
      nonconformingValues: [
        ...preservedNCVArray,
        {
          kind: item.nonconformingKind,
          value: item.value,
        },
      ],
      ...additionalProps,
    };
  }
}

export function setUserDefinedValueFor (
  index: number,
  value: string,
  parent: QuestionLikeChoiceHolder,
  answer: Answer<ValueAtom>,
  additionalProps: any = {}
): GeneralAnswer {
  const base = parent.isMulti ?
    {isMulti: true, values: [...(answer.values ?? [])]} :
    {isMulti: false, value: {value: null}};
  return ({
    ...base,
    // TODO: do we need to preserve other (toggleable) answers?
    nonconformingValues: [
      {
        kind: NonconformingValueKind.UserDefined,
        value
      }
    ],
    ...additionalProps
  });
}

export function getUserDefinedValue (answer?: Answer<ValueAtom>): string | null {
  return answer?.nonconformingValues?.find(ncv => ncv.kind === NonconformingValueKind.UserDefined)?.value;
}
