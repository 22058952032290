import { Action, createAsyncThunk, AsyncThunk, createEntityAdapter, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createSliceWithThunks } from "../../utils/redux";
import { isValidLanguage, Language, LanguageISO, LanguageList } from "../../constants/locales";
import { queryBoolean } from "../../utils/hooks";
import { NEGATIVES, POSITIVES } from "../../components/tempConstants";
import { authSlice } from "./auth";

export interface BooleanSimulatorState {
  isActive: boolean;
  dualLanguage: boolean;
  simulateKiosk: boolean;
  simulateFullProvider: boolean;
  debugHighlighting: boolean;
  trackingInfo: boolean;
  autoProgressScalars: boolean;
  noDisableProgress: boolean;
  navLogicEnabled: boolean;
  conditionalChoicesEnabled: boolean;
  exhaustiveLoops: boolean;
  showExpandedLanguages: boolean;
}
export interface SimulatorState extends BooleanSimulatorState {
  language: LanguageISO;
  overrides: Record<string, any>;
}
// KEEP UPDATED AS STATE GETS MORE KEYS
function isBooleanKey (k : keyof SimulatorState) : k is keyof BooleanSimulatorState {
  return !["language", "overrides"].includes(k);
}

const initialState : SimulatorState = {
  language: Language.English,
  isActive: false,
  dualLanguage: false,
  simulateKiosk: true,
  simulateFullProvider: true,
  debugHighlighting: false,
  trackingInfo: false,
  autoProgressScalars: false,
  noDisableProgress: false,
  navLogicEnabled: true,
  conditionalChoicesEnabled: true,
  exhaustiveLoops: false,
  overrides: {_bodyType: "female"},
  showExpandedLanguages: false,
};

export const simulatorSlice = createSliceWithThunks({
  name: 'simulator',
  initialState,
  reducers: (create) => ({
    activate: create.reducer((state, action) => {
      state.isActive = true;
    }),

    readSettingsFromSearchParams: create.reducer((state: SimulatorState, action: Action) => {
      const usp = new URLSearchParams(window.location.search)
      for (let [k, v] of Array.from(usp.entries())) {
        const stateKeys = Object.keys(state) as Array<keyof SimulatorState>;
        const matchingKey : keyof SimulatorState | undefined = stateKeys.find(sk => sk.toLocaleLowerCase() === k.toLocaleLowerCase());
        if (matchingKey === undefined) {
          continue;
        }
        if (!isBooleanKey(matchingKey)) {
          if (k === "language") {
            if (isValidLanguage(matchingKey)) {
              state.language = matchingKey;
            } else {
              console.error(`Search parameters had unrecognized language "${matchingKey}"`);
            }
          }
          // NOTE: language is the only non-boolean key we accept via URL params
        } else {
          // k is a matching boolean key!
          if (POSITIVES.includes(v.toLocaleLowerCase())) {
            state[matchingKey] = true;
          } else if (NEGATIVES.includes(v.toLocaleLowerCase())) {
            state[matchingKey] = false;
          } else {
            console.error(`Search parameters had unrecognized boolean value "${k}"="${v}"`);
          }
        }
      }
    }),

    changeSimulatorSettings: create.reducer((state: SimulatorState, action: PayloadAction<Partial<SimulatorState>>) => {
      return {...state, ...action.payload};
    })
  })
});

export const {changeSimulatorSettings, readSettingsFromSearchParams} = simulatorSlice.actions;