import DateFnsUtils from "@date-io/date-fns";
import { createTheme } from "@material-ui/core";
import {
  DatePickerView,
  Day,
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { ThemeProvider } from "@material-ui/styles";
import moment from "moment";
import { MouseEvent, useMemo, useState } from "react";
import { GeneralAnswer } from "../../../models/answers";
import { CalendarQuestionDefinition } from "../../../models/questions";
import { useAppSelector } from "../../../utils/hooks";
import { DateLocales } from "../../../constants/locales";

// Todo: remove the CalendarHeader.  **OR**
// Todo: have Carlos suggest design with this calendar given year selection unless can be further modified per specs
// Todo: daySelected focus backgroundColor: #E3F4E8, color: var(--evergreen) (and no outline)
// Todo: dark text color is #1B1B1B

const materialTheme = createTheme({
  overrides: {
    MuiPickersBasePicker: {
      pickerView: {
        maxWidth: 500,
        width: "100%",
      },
      container: {
        alignItems: "center",
      },
      containerLandscape: {
        flexDirection: "column",
      },
    },

    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: "var(--evergreen)",
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        cursor: "pointer"
      },
      daysHeader: {
        justifyContent: "space-around",
      },
    },
    MuiPickersCalendar: {
      week: {
        justifyContent: "space-around",
        // marginTop: "0.5em"
      },
    },
    MuiPickersDay: {
      daySelected: {
        backgroundColor: "var(--evergreen)",
        "&:hover": {
          backgroundColor: "#0fd181",
        },
        // backgroundColor:  "#E3F4E8",
      },
    },
  },
});

const ARROW_KEYS = ["ArrowRight", "ArrowLeft", "ArrowUp", "ArrowDown"];
function preventCalendarNavPropagation(e: KeyboardEvent) {
  if (ARROW_KEYS.includes(e.key)) {
    e.stopPropagation();
  }
}

const VIEW_ORDER: DatePickerView[] = ["year", "month", "date"];

const INITIAL_VIEW: DatePickerView = VIEW_ORDER[2];

/**
 * A response component for handling *calendar* date inputs. We emphasize the
 * calendar aspect as these dates are not given times and their location data
 * is assumed to be contextual -- we never do date manipulations upon them
 * because this is more likely to create confusion (for example a patient's
 * date of birth being displayed as a different day due to timezones; it is
 * rarely relevant what the date was in timezones/offsets other than the one
 * that is contextually implied). Because of this, we also store them on the
 * server as YYYY-MM-DD strings.
 *
 * If a time is needed, we will use the (currently unimplemented) timestamp
 * response type, or perhaps an (unspec'ed) time+location combo type.
 *
 * @param {*} param0
 * @returns
 */
export default function CalendarDateResponse({
  questionDefinition,
  answer,
  updateAnswer,
}: {
  questionDefinition: CalendarQuestionDefinition;
  answer: GeneralAnswer;
  updateAnswer: (a: GeneralAnswer) => void;
}) {
  function onDateChange(newDate: Date) {
    updateAnswer({
      key: questionDefinition.id,
      isMulti: false,
      value: {
        value: newDate.valueOf(),
      },
      // do not preserve NCVs
    });
    
    // every time we accept an answer we move to the next view
    const viewStep = VIEW_ORDER.indexOf(currentView);
    if (viewStep < VIEW_ORDER.length - 1) {
      setCurrentView(VIEW_ORDER[viewStep + 1]);
    }
  }
  const language = useAppSelector((s) => s.patientFlow.language);
  const dateLocale = DateLocales[language];

  function CalendarDay(day, selectedDate, dayInCurrentMonth, dayComponent) {
    const dayInDisabledRange =
      (questionDefinition?.disableFuture && moment(day).isAfter()) ||
      (questionDefinition?.disablePast && moment(day).isBefore());
    return (
      <Day
        style={{
          opacity: dayInCurrentMonth ? (dayInDisabledRange ? 0.5 : 1) : 0.25,
        }}
        selected={
          !!answer?.value?.value &&
          moment(day).isSame(answer?.value?.value, "day")
        }
        // onClick={() => onDateChange(day)} // handled by Calendar.onChange
      >
        {day.getDate()}
      </Day>
    );
  }

  const dateAnswer = useMemo(() => {
    if ((answer.nonconformingValues?.length ?? -1) > 0) {
      return new Date();
    } else if (typeof answer?.value?.value !== "number") {
      if (Array.isArray(questionDefinition.initialDateDelta)) {
        return moment()
          .add(...questionDefinition.initialDateDelta)
          .toDate();
      } else {
        return new Date();
      }
    } else {
      return new Date(answer.value.value);
    }
  }, [answer]);

  const [currentView, setCurrentView] = useState<DatePickerView>(INITIAL_VIEW);
  function handleHeaderClick(e: MouseEvent) {
    setCurrentView("year");
  }
  function checkForHeaderClick(e: MouseEvent<HTMLElement>, ...x: any[]) {
    let element: HTMLElement | null = e.target as HTMLElement;
    while (
      element &&
      !element.classList.contains("dateQuestionWrapper") &&
      "parentElement" in element
    ) {
      if (
        element.classList.contains(
          "MuiPickersCalendarHeader-transitionContainer"
        )
      ) {
        return handleHeaderClick(e);
      }
      element = element.parentElement;
    }
  }

  return (
    <div
    className="dateQuestionWrapper main-column"
    onKeyUp={preventCalendarNavPropagation}
    onClick={checkForHeaderClick}
    dir="ltr"
    >
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={dateLocale}>
        <ThemeProvider theme={materialTheme}>
          <KeyboardDatePicker
            autoOk
            variant="static"
            value={dateAnswer}
            views={VIEW_ORDER}
            openTo={currentView}
            key={currentView}
            // above is hackish idea suggested by github commenters; basically
            // just force a rerender when you want to change view and use the
            // openTo prop to ensure it starts at the new view
            // (apparently this is all solved in v4)
            // The alternative is to use ToolbarComponent to grab a copy of the
            // internal function that changes the view state and expose it on
            // a ref, but that risks a flow loop a little bit.
            renderDay={CalendarDay}
            onChange={onDateChange}
            disableFuture={questionDefinition?.disableFuture}
            disablePast={questionDefinition?.disablePast}
            disableToolbar={true}
          />
        </ThemeProvider>
      </MuiPickersUtilsProvider>
    </div>
  );
}
