import { useState } from "react";
import moment from "moment";
import "./debug.css";
import { oneline, ordinals, randomId } from "../../../utils";
import { CTA } from "../../UI/buttons/CTA";
import { ProviderDashboard } from "../../Provider/Dashboard";
import { advancePage } from "../../../store/slices/patient-flow";
import { LoginComponent } from "../../Auth/Login";


function randTimestampInLastWeek() {
  return Date.now() - Math.floor(Math.random() * 1000 * 60 * 60 * 24 * 7);
}
function randComplaint() {
  return [
    "Chest pain",
    "Trouble breathing",
    "Cough",
    "Vomiting",
    "Nausea",
    "Diarrhea",
    "Urinary problem",
    "Genital problem",
    "Headache",
    "Abdominal pain",
    "Pelvic pain",
    "Muscles, bone, or joint pain ",
  ][Math.floor(Math.random() * 12)];
}
function randSex() {
  return Math.random() > 0.5 ? "Female" : "Male";
}
function randAge() {
  return Math.floor((Math.random() + Math.random()) * 53);
}
function randEntry() {
  return {
    date: randTimestampInLastWeek(),
    status: Math.random() > 0.8 ? 0 : 1,
    age: randAge(),
    sex: randSex(),
    cc: randComplaint(),
    id: randomId(4),
  };
}
let recordCount = Math.floor(Math.random() * 8) + 8;
const SAMPLE_DASHBOARD_DATA = ordinals(recordCount).map(randEntry);


export const DebugPage = ({ debugType, ...props }) => {
  console.warn(props);
  console.warn(debugType);
  switch (debugType) {
    case "login":
      return <LoginComponent actionOverride={() => advancePage()} />;
    case "dashboard":
      return <ProviderDashboard simulation={{
        answerSets: SAMPLE_DASHBOARD_DATA,
        kioskAction: () => advancePage()
      }} />;
    default:
      return <h3>Unknown debug page type {debugType}</h3>;
  }
};
