/**
 * This file defines types, constants, and utility functions for working with measurement units 
 * and systems of measure. It supports both metric and imperial systems, and accommodates 
 * the representation of single-unit (atomic) and multi-unit (subdivided) measurements. 
 * The functionality includes conversion, default handling, and metadata for localization.
 */

import { 
  PseudoMeasurementQuestionDefinition,
   QuestionDefinition
 } from "./questions";

// We may get more specific later...
export type UnitLabel = string;

export const METRIC = "metric";
export const IMPERIAL = "us";
export enum SystemOfMeasure {
  Metric = METRIC,
  Imperial = IMPERIAL,
  Nonstandard = "nonstandard" // ?? Is an example of "non-standard" "per week" as a measurement?
};

export const ESTABLISHED_SYSTEMS = [SystemOfMeasure.Imperial, SystemOfMeasure.Metric];

// Describes a single-unit measurement, such as "kilograms" or "pounds"
export interface AtomicUnit {
  isProviderPreferred?: boolean;
  isTuple?: false;
  unit: UnitLabel; // Abbreviation of the unit (e.g., "kg")
  unitTranslationIds?: [string, string],
  unitLong: string;  // Full name of the unit (e.g., "kilograms")
  default?: number;
  conversionFactor: number;
  max?: number;
  min?: number;
  step?: number;
  listExhaustively?: boolean; // Indicates if all values within the range should be enumerated
};

// Describes a multi-part measurement, such as "feet and inches".
export interface SubdividedUnit {
  isProviderPreferred?: boolean;
  isTuple: true;
  unit: UnitLabel[]; // Abbreviations of the units (e.g., "ft", "in")
  unitTranslationIds?: Array<[string, string]>,
  unitLong: string[]; // Full names of the units (e.g., "feet", "inches")
  default?: number[]; // Default values for each part of the unit (e.g., "5 feet", "4 inches")
  conversionFactor: number[];
  max?: number[];
  min?: number[];
  step?: number[];
  listExhaustively?: boolean[]; // Indicates if all values within the range should be enumerate
};

export type Unit = AtomicUnit | SubdividedUnit;

/**
 * Converts a `Unit` to an array of `AtomicUnit`s. This is useful for treating 
 * subdivided units as individual atomic components
 * 
 * @param unit - The unit to convert.
 * @returns An array of `AtomicUnit` objects.
 */
export function toAtomArray (unit : Unit) : AtomicUnit[] {
  if (unit.isTuple) {
    return unit.unit.map((unitLabel, i) => {
      return ({
        unit: unitLabel,
        unitLong: unit.unitLong?.[i],
        max: unit.max?.[i],
        min: unit.min?.[i],
        step: unit.step?.[i],
        listExhaustively: unit.listExhaustively?.[i],
        conversionFactor: unit.conversionFactor?.[i],
        unitTranslationIds: unit.unitTranslationIds?.[i]
      });
    })
  } else {
    return [{...unit}];
  }
}

// Defines a dimension of measurement, such as length or weight, with configurations 
// for metric, imperial, and non-standard systems
export type Dimension = {
  [METRIC]?: Unit,
  [IMPERIAL]?: Unit,
  [SystemOfMeasure.Nonstandard]?: Unit
}

// Represents a measurement value and its associated unit
export interface NumberAndUnitType {
  value: number | null;
  unit: UnitLabel;
  index: number; // Index of the unit (useful for subdivided units)
};

// ?? Is "pseudo-measurement" referring to non-standard measurements such as "per week" and "years ago"?
/**
 * Creates an `AtomicUnit` from a `PseudoMeasurementQuestionDefinition`.
 * This is used to map a pseudo-measurement definition into a standard atomic unit configuration.
 * 
 * @param qd - The pseudo-measurement question definition.
 * @returns An `AtomicUnit` object.
 */
export function pseudoMeasurementUnit (qd: PseudoMeasurementQuestionDefinition): AtomicUnit {
  return ({
    isTuple: false,
    unit: qd.unit,
    unitLong: qd.unitLong ?? qd.unit,
    unitTranslationIds: [qd.unitLongTranslationId ?? qd.unitTranslationId ?? "--", qd.unitTranslationId ?? "--"],
    default: qd.initialValue ?? 0,
    max: qd.max ?? 100,
    min: qd.min ?? 0,
    step: qd.step ?? 1,
    listExhaustively: true,
    conversionFactor: 1.0
  });
}