import { AnatomicalModel, ClickResolutionMethod, SVGRegionSelectorType } from "../models/anatomical-models";

/**
 * Whether the app should draw connecting lines to ALL regions, regardless of
 * whether they are selected. These lines will be rendered partially trasparent.
 */
export const USE_SEMITRANSPARENT_LINES = true;
/**
 * Whether the app should fade out non-selected choices in the legend (once at
 * least one choice other than the current view's overall is chosen). Otherwise
 * they will all be rendered completely opaque.
 */
export const USE_SEMITRANSPARENT_CHOICES = false;
/**
 * Whether the view's image should attempt to embed an SVG image as a native
 * elemen t of the page (as opposed to the src of an <img> tag or other use that
 * treats it as a separate document). This requires some trickery with webpack,
 * see DynamicSVG for more details, but allows us to do runtime JS interactions
 * with the SVG itself slash apply CSS to its children.
 * 
 * NOTE: At this point, turning this off will break a number of features!!!
 */
export const USE_DYNAMIC_SVG_EMBED = true;
/**
 * Whether the app should attempt to display region highlighting by modifying
 * the CSS of nodes within the SVG. This does nothing when USE_DYNAMIC_SVG_EMBED
 * is off, and has further configuration at SVG_SELECTION_TYPE.
 */
export const HIGHLIGHT_BY_SVG_NODES = true;
/**
 * Whether a model/view's coordinate pairs should be treated as having their
 * origin at the top left of the SVG element, or if they should be relative to
 * the box that handled the click event.
 * 
 * This setting is mostly irrelevant since now all click events are handled by
 * the overall image, so the settings are 99% equivalent.
 */
export const POINTS_ZEROED_TO_SVG_ROOT = true;

// unsure about this usage, I believe it has been phased out
export const DEFAULT_REGION_DEF = [];

export const SVG_SELECTION_TYPE : SVGRegionSelectorType =
  SVGRegionSelectorType.GroupIdMatchesRegionHighlightId;

/**
 * The previous set up before our interaction model was simplified. Typed to
 * any because its views haven't been updated to be compliant with API changes.
 */
export const DEFAULT_HUMAN_MODEL_V1 : any = {};

const DEFAULT_HUMAN_MODEL_V2 : AnatomicalModel = {
  "body front": {
    "isRoot": true,
    "isLeaf": false,
    "imgUrl": "view-assets/rule-of-3/anatomical_model_full body_{SEX}.svg",
    "choices": [
      {
        "viewId": "head",
        "label": "Head",
        "labelTranslationId": "R_ANT025",
        "highlightRegion": "Head",
        "lineAnchor": {f: [496, 105], m: [506, 54]}
      },
      {
        "viewId": "chest",
        "label": "Chest",
        "labelTranslationId": "R_ANT005",
        "highlightRegion": "Chest",
        "lineAnchor": [507, 250]
      },
      {
        "viewId": "left arm",
        "label": "Left Arm",
        "labelTranslationId": "R_ANT014",
        "highlightRegion": "Arm-Left",
        "lineAnchor": {f: [598, 327], m: [625, 308]}
      },
      {
        "viewId": "right arm",
        "label": "Right Arm",
        "labelTranslationId": "R_ANT013",
        "highlightRegion": "Arm-Right",
        "lineAnchor": {f: [418, 327], m: [382, 308]}
      },
      {
        "viewId": "abdomen",
        "label": "Belly",
        "labelTranslationId": "R_ANT103",
        "highlightRegion": "Ab",
        "lineAnchor": [506, 375]
      },
      {
        "viewId": "pelvis",
        "label": "Hips & Pelvis",
        "labelTranslationId": "R_ANT063",
        "highlightRegion": "HipsAndPelvis",
        "lineAnchor": [507, 452]
      },
      {
        "viewId": "left hand",
        "label": "Left Hand",
        "labelTranslationId": "R_ANT152",
        "highlightRegion": "Hand-Left",
        "lineAnchor": {f: [642, 500], m: [680, 520]}
      },
      {
        "viewId": "right hand",
        "label": "Right Hand",
        "labelTranslationId": "R_ANT153",
        "highlightRegion": "Hand-Right",
        "lineAnchor": {f: [365, 500], m: [321, 513]}
      },
      {
        "viewId": "left leg",
        "label": "Left Leg",
        "labelTranslationId": "R_ANT154",
        "highlightRegion": "Leg-FrontLeft",
        "lineAnchor": [544, 631]
      },
      {
        "viewId": "right leg",
        "label": "Right Leg",
        "labelTranslationId": "R_ANT155",
        "highlightRegion": "Leg-FrontRight",
        "lineAnchor": [460, 631]
      },
      {
        "viewId": "left foot",
        "label": "Left Foot",
        "labelTranslationId": "R_ANT156",
        "highlightRegion": ["Foot-FrontLeft", "Foot-Left"], // 1st F, 2nd M
        "lineAnchor": {f: [529, 975], m: [560, 951]}
      },
      {
        "viewId": "right foot",
        "label": "Right Foot",
        "labelTranslationId": "R_ANT157",
        "highlightRegion": ["Foot-FrontRight", "Foot-Right"], // 1st F, 2nd M
        "lineAnchor": {f: [477, 975], m: [448, 951]}
      },
      {
        "viewId": "back",
        "label": "Back",
        "labelTranslationId": "R_ANT024",
        "highlightRegion": "Back",
        // "lineAnchor": [569, 366]
      }
    ]
  },
  "head": {
    "isRoot": false,
    "isLeaf": true,
    "imgUrl": "view-assets/rule-of-3/anatomical_model_head_{SEX}.svg",
    "allHighlightRegionId": "Head",
    "choices": [
      {
        "value": "Top of head",
        "label": "Top",
        "labelTranslationId": "R_ANT026",
        "highlightRegion": "Head-Top",
        "lineAnchor": {f: [518, 82], m: [500, 46]}
      },
      {
        "value": "Left Forehead",
        "label": "Left Forehead",
        "labelTranslationId": "R_ANT129",
        "highlightRegion": "Head-ForeheadLeft",
        "lineAnchor": {f: [478, 184], m: [599, 171]}
      },
      {
        "value": "Right Forehead",
        "label": "Right Forehead",
        "labelTranslationId": "R_ANT130",
        "highlightRegion": "Head-ForeheadRight",
        "lineAnchor": {f: [328, 193], m: [423, 171]}
      },
      {
        "value": "Left Temple",
        "label": "Left Temple",
        "labelTranslationId": "R_ANT131",
        "highlightRegion": "Head-TempleLeft",
        "lineAnchor": {f: [534, 283], m: [683, 290]}
      },
      {
        "value": "Right Temple",
        "label": "Right Temple",
        "labelTranslationId": "R_ANT132",
        "highlightRegion": "Head-TempleRight",
        "lineAnchor": {f: [264, 303], m: [348, 290]}
      },
      {
        "value": "Left Eye",
        "label": "Left Eye",
        "labelTranslationId": "R_ANT133",
        "highlightRegion": "Head-EyeLeft",
        "lineAnchor": {f: [429, 329], m: [606, 335]}
      },
      {
        "value": "Right Eye",
        "label": "Right Eye",
        "labelTranslationId": "R_ANT134",
        "highlightRegion": "Head-EyeRight",
        "lineAnchor": {f: [325, 340], m: [430, 335]}
      },
      {
        "value": "Left Ear",
        "label": "Left Ear",
        "labelTranslationId": "R_ANT135",
        "highlightRegion": "Head-EarLeft",
        "lineAnchor": {f: [631, 361], m: [721, 379]}
      },
      {
        "value": "Right Ear",
        "label": "Right Ear",
        "labelTranslationId": "R_ANT136",
        "highlightRegion": "Head-EarRight",
        "lineAnchor": {f: null, m: [303, 375]}
        // f: [247, 389]
      },
      {
        "value": "Nose",
        "label": "Nose",
        "labelTranslationId": "R_ANT030",
        "highlightRegion": "Head-Nose",
        "lineAnchor": {f: [378, 383], m: [532, 409]}
      },
      {
        "value": "Mouth",
        "label": "Mouth",
        "labelTranslationId": "R_ANT032",
        "highlightRegion": "Head-Mouth",
        "lineAnchor": {f: [392, 436], m: [522, 476]}
      },
      {
        "value": "Left Jaw",
        "label": "Left Jaw",
        "labelTranslationId": "R_ANT137",
        "highlightRegion": "Head-JawLeft",
        "lineAnchor": {f: [489, 517], m: [619, 519]}
      },
      {
        "value": "Right Jaw",
        "label": "Right Jaw",
        "labelTranslationId": "R_ANT138",
        "highlightRegion": "Head-JawRight",
        "lineAnchor": {f: [312, 524], m: [412, 520]}
      },
      {
        "value": "Chin",
        "label": "Chin",
        "labelTranslationId": "R_ANT139",
        "highlightRegion": "Head-Chin",
        "lineAnchor": {f: [393, 549], m: [517, 559]}
      },
      {
        "value": "Neck",
        "label": "Neck",
        "labelTranslationId": "R_ANT003",
        "highlightRegion": "Head-Neck",
        "lineAnchor": {f: [500, 637], m: [515, 624]}
      }
    ]
  },
  "chest": {
    "isRoot": false,
    "isLeaf": true,
    "imgUrl": "view-assets/rule-of-3/anatomical_model_chest_{SEX}.svg",
    "allHighlightRegionId": "Chest",
    "choices": [
      {
        "value": "Left Collarbone",
        "label": "Left Collarbone",
        "labelTranslationId": "R_ANT142",
        "highlightRegion": "Chest-CollarboneLeft",
        "lineAnchor": [566, 112]
      },
      {
        "value": "Right Collarbone",
        "label": "Right Collarbone",
        "labelTranslationId": "R_ANT143",
        "highlightRegion": "Chest-CollarboneRight",
        "lineAnchor": [429, 110]
      },
      {
        "value": "Upper Left Chest",
        "label": "Upper Left Chest",
        "labelTranslationId": "R_ANT144",
        "highlightRegion": "Chest-UpperChestLeft",
        "lineAnchor": [432, 162]
      },
      {
        "value": "Upper Right Chest",
        "label": "Upper Right Chest",
        "labelTranslationId": "R_ANT145",
        "highlightRegion": "Chest-UpperChestRight",
        "lineAnchor": [578, 162]
      },
      {
        "value": "Sternum",
        "label": "Sternum",
        "labelTranslationId": "R_ANT037",
        "highlightRegion": "Chest-Sternum",
        "lineAnchor": [513, 216]
      },
      {
        "value": "Left Breast",
        "label": "Left Breast",
        "labelTranslationId": "R_ANT146",
        "highlightRegion": "Chest-BreastLeft",
        "onlyFemale": true,
        "lineAnchor": [612, 282]
      },
      {
        "value": "Right Breast",
        "label": "Right Breast",
        "labelTranslationId": "R_ANT147",
        "highlightRegion": "Chest-BreastRight",
        "onlyFemale": true,
        "lineAnchor": [413, 282]
      },
      {
        "value": "Left Ribs",
        "label": "Left Rib",
        "labelTranslationId": "R_ANT148",
        "highlightRegion": "Chest-RibsLeft",
        "lineAnchor": [581, 311]
      },
      {
        "value": "Right Ribs",
        "label": "Right Rib",
        "labelTranslationId": "R_ANT149",
        "highlightRegion": "Chest-RibsRight",
        "lineAnchor": [439, 311]
      },
      {
        "value": "Lower Left Chest",
        "label": "Lower Left Chest",
        "labelTranslationId": "R_ANT150",
        "highlightRegion": "Chest-LowerChestLeft",
        "lineAnchor": [574, 375]
      },
      {
        "value": "Lower Right Chest",
        "label": "Lower Right Chest",
        "labelTranslationId": "R_ANT151",
        "highlightRegion": "Chest-LowerChestRight",
        "lineAnchor": [441, 375]
      },
      {
        "value": "Diaphragm",
        "label": "Diaphragm",
        "labelTranslationId": "R_PRG045",
        "highlightRegion": "Chest-Diaphragm",
        "lineAnchor": [508, 350]
      }
    ]
  },
  "abdomen": {
    "isRoot": false,
    "isLeaf": true,
    "imgUrl": "view-assets/rule-of-3/anatomical_model_belly_{SEX}.svg",
    "allHighlightRegionId": "Ab",
    "choices": [
      {
        "value": "Upper Left",
        "label": "Upper Left",
        "labelTranslationId": "R_ANT113",
        "highlightRegion": "Ab-UpperLeft",
        "lineAnchor": [593, 304]
      },
      {
        "value": "Upper Center",
        "label": "Upper Center",
        "labelTranslationId": "R_ANT040", // R_ANT112
        "highlightRegion": "Ab-UpperCenter",
        "lineAnchor": [505, 301]
      },
      {
        "value": "Upper Right",
        "label": "Upper Right",
        "labelTranslationId": "R_ANT111",
        "highlightRegion": "Ab-UpperRight",
        "lineAnchor": [420, 307]
      },
      {
        "value": "Mid Left",
        "label": "Mid Left",
        "labelTranslationId": "R_ANT116",
        "highlightRegion": "Ab-MiddleLeft",
        "lineAnchor": [605, 433]
      },
      {
        "value": "Mid Center",
        "label": "Mid Center",
        "labelTranslationId": "R_ANT043", // R_ANT115
        "highlightRegion": "Ab-MiddleCenter",
        "lineAnchor": [505, 433]
      },
      {
        "value": "Mid Right",
        "label": "Mid Right",
        "labelTranslationId": "R_ANT114",
        "highlightRegion": "Ab-MiddleRight",
        "lineAnchor": [406, 433]
      },
      {
        "value": "Lower Left",
        "label": "Lower Left",
        "labelTranslationId": "R_ANT119",
        "highlightRegion": "Ab-LowerLeft",
        "lineAnchor": [617, 555]
      },
      {
        "value": "Lower Center",
        "label": "Lower Center",
        "labelTranslationId": "R_ANT046", // R_ANT118
        "highlightRegion": "Ab-LowerCenter",
        "lineAnchor": [505, 556]
      },
      {
        "value": "Lower Right",
        "label": "Lower Right",
        "labelTranslationId": "R_ANT117",
        "highlightRegion": "Ab-LowerRight",
        "lineAnchor": [393, 555]
      },
    ]
  },
  "back": {
    "isRoot": false,
    "isLeaf": true,
    "imgUrl": "view-assets/rule-of-3/anatomical_model_back_{SEX}.svg",
    "allHighlightRegionId": "Back",
    "choices": [
      {
        "value": "Back of Head",
        "label": "Back of Head",
        "labelTranslationId": "R_ANT107",
        "highlightRegion": "Back-Head",
        "lineAnchor": {f: [509, -52], m: [500, 75]}
      },
      {
        "value": "Neck Muscles",
        "label": "Neck Muscles",
        "labelTranslationId": "R_ANT085",
        "highlightRegion": "Back-Neck",
        "lineAnchor": {f: [531, 134], m: [554, 174]}
      },
      {
        "value": "Cervical Vertebrae",
        "label": "Neck Bones",
        "labelTranslationId": "R_ANT064",
        "highlightRegion": "Back-NeckSpine",
        "lineAnchor": {f: [507, 150], m: [502, 179]}
      },
      {
        "value": "Thoracic Vertebrae",
        "label": "Back Bones",
        "labelTranslationId": "R_ANT089",
        "highlightRegion": "Back-Spine",
        "lineAnchor": [507, 367]
      },
      {
        "value": "Upper Back Left",
        "label": "Upper Back Left",
        "labelTranslationId": "R_ANT158",
        "highlightRegion": "Back-UpperBackLeft",
        "lineAnchor": [420, 304]
      },
      {
        "value": "Upper Back Right",
        "label": "Upper Back Right",
        "labelTranslationId": "R_ANT159",
        "highlightRegion": "Back-UpperBackRight",
        "lineAnchor": [593, 304]
      },
      {
        "value": "Middle Back Left",
        "label": "Middle Back Left",
        "labelTranslationId": "R_ANT160",
        "highlightRegion": "Back-MidBackLeft",
        "lineAnchor": [434, 533]
      },
      {
        "value": "Middle Back Right",
        "label": "Middle Back Right",
        "labelTranslationId": "R_ANT161",
        "highlightRegion": "Back-MidBackRight",
        "lineAnchor": [582, 533]
      },
      {
        "value": "Lower Back Left",
        "label": "Lower Back Left",
        "labelTranslationId": "R_ANT162",
        "highlightRegion": "Back-LowBackLeft",
        "lineAnchor": [421, 706]
      },
      {
        "value": "Lower Back Right",
        "label": "Lower Back Right",
        "labelTranslationId": "R_ANT163",
        "highlightRegion": "Back-LowBackRight",
        "lineAnchor": [595, 706]
      },
    ]
  },
  "pelvis": {
    "isRoot": false,
    "isLeaf": true,
    "imgUrl": "view-assets/rule-of-3/anatomical_model_hips_pelvis_{SEX}.svg",
    "allHighlightRegionId": ["HipsAndPelvis", "HipsAndPelvis-Back"],
    "choices": [
      {
        "value": "Pelvis",
        "label": "Pelvis",
        "labelTranslationId": "R_ANT104",
        "highlightRegion": "HipsAndPelvis-Pelvis",
        "onlyFemale": true,
        "lineAnchor": [380, 212]
      },
      {
        "value": "Hips",
        "label": "Hips",
        "labelTranslationId": "R_ANT105",
        "highlightRegion": "HipsAndPelvis-Hips",
        "lineAnchor": {f: [455, 226], m: [436, 261]}
      },
      {
        "value": "Sacrum",
        "label": "Base of the spine",
        "labelTranslationId": "R_ANT097",
        "highlightRegion": "HipsAndPelvis-BaseOfTheSpine",
        "lineAnchor": {f: [636, 187], m: [660, 217]}
      },
      {
        "value": "Coccyx",
        "label": "Tailbone",
        "labelTranslationId": "R_ANT102",
        "highlightRegion": "HipsAndPelvis-Tailbone",
        "lineAnchor": {f: [636, 214], m: [660, 254]}
      },
      {
        "value": "Gluteal Muscles",
        "label": "Buttocks",
        "labelTranslationId": "R_ANT065",
        "highlightRegion": "HipsAndPelvis-Buttocks",
        "lineAnchor": {f: [587, 240], m: [617, 294]}
      },
      {
        "value": "Groin",
        "label": "Groin",
        "labelTranslationId": "R_ANT016",
        "highlightRegion": "HipsAndPelvis-Groin",
        "lineAnchor": {f: [402, 261], m: [371, 309]}
      },
      {
        "value": "Pubis",
        "label": "Pubis",
        "labelTranslationId": "R_ANT110",
        "highlightRegion": "HipsAndPelvis-Pubis",
        "onlyFemale": true,
        "lineAnchor": [378, 260]
      },
    ]
  },
  "left arm": {
    "isRoot": false,
    "isLeaf": true,
    "imgUrl": "view-assets/rule-of-3/anatomical_model_armLeft_{SEX}.svg",
    "allHighlightRegionId": "Arm-Left",
    "choices": [
      {
        "value": "Left Shoulder",
        "label": "Shoulder",
        "labelTranslationId": "R_ANT049",
        "highlightRegion": "Arm-ShoulderLeft",
        "lineAnchor": [492, 154]
      },
      {
        "value": "Left Bicep",
        "label": "Bicep",
        "labelTranslationId": "R_ANT050",
        "highlightRegion": "Arm-BicepLeft",
        "lineAnchor": [508, 293]
      },
      {
        "value": "Left Elbow",
        "label": "Elbow",
        "labelTranslationId": "R_ANT052",
        "highlightRegion": "Arm-ElbowLeft",
        "lineAnchor": [520, 406]
      },
      {
        "value": "Left Forearm",
        "label": "Forearm",
        "labelTranslationId": "R_ANT053",
        "highlightRegion": "Arm-ForearmLeft",
        "lineAnchor": [548, 518]
      }
    ]
  },
  "right arm": {
    "isRoot": false,
    "isLeaf": true,
    "imgUrl": "view-assets/rule-of-3/anatomical_model_armRight_{SEX}.svg",
    "allHighlightRegionId": "Arm-Right",
    "choices": [
      {
        "value": "Right Shoulder",
        "label": "Shoulder",
        "labelTranslationId": "R_ANT049",
        "highlightRegion": "Arm-ShoulderRight",
        "lineAnchor": [506, 149]
      },
      {
        "value": "Right Bicep",
        "label": "Bicep",
        "labelTranslationId": "R_ANT050",
        "highlightRegion": "Arm-BicepRight",
        "lineAnchor": [490, 294]
      },
      {
        "value": "Right Elbow",
        "label": "Elbow",
        "labelTranslationId": "R_ANT052",
        "highlightRegion": "Arm-ElbowRight",
        "lineAnchor": [459, 402]
      },
      {
        "value": "Right Forearm",
        "label": "Forearm",
        "labelTranslationId": "R_ANT053",
        "highlightRegion": "Arm-ForearmRight",
        "lineAnchor": [429, 528]
      }
    ]
  },
  "left hand": {
    "isRoot": false,
    "isLeaf": true,
    "imgUrl": "view-assets/rule-of-3/anatomical_model_hand.svg",
    "allHighlightRegionId": "Hand_2",
    "choices": [
      {
        "value": "Left Palm",
        "label": "Palm",
        "labelTranslationId": "R_ANT056",
        "highlightRegion": "Hand-Palm",
        "lineAnchor": [483, 284]
      },
      {
        "value": "Left Wrist",
        "label": "Wrist",
        "labelTranslationId": "R_ANT055",
        "highlightRegion": "Hand-Wrist",
        "lineAnchor": [472, 390]
      },
      {
        "value": "Left Thumb",
        "label": "Thumb",
        "labelTranslationId": "R_ANT057",
        "highlightRegion": "Hand-Thumb",
        "lineAnchor": [630, 277]
      },
      {
        "value": "Left Index Finger",
        "label": "Index Finger",
        "labelTranslationId": "R_ANT058",
        "highlightRegion": "Hand-IndexFinger",
        "lineAnchor": [533, 141]
      },
      {
        "value": "Left Middle Finger",
        "label": "Middle Finger",
        "labelTranslationId": "R_ANT059",
        "highlightRegion": "Hand-MiddleFiner",
        "lineAnchor": [482, 125]
      },
      {
        "value": "Left Ring Finger",
        "label": "Ring Finger",
        "labelTranslationId": "R_ANT060",
        "highlightRegion": "Hand-RingFinger",
        "lineAnchor": [435, 144]
      },
      {
        "value": "Left Pinky Finger",
        "label": "Pinky Finger",
        "labelTranslationId": "R_ANT061",
        "highlightRegion": "Hand-PinkyFinger",
        "lineAnchor": [375, 197]
      },
    ]
  },
  "right hand": {
    "isRoot": false,
    "isLeaf": true,
    "imgUrl": "view-assets/rule-of-3/anatomical_model_hand.svg",
    "allHighlightRegionId": "Hand_2",
    "choices": [
      {
        "value": "Right Palm",
        "label": "Palm",
        "labelTranslationId": "R_ANT056",
        "highlightRegion": "Hand-Palm",
        "lineAnchor": [483, 284]
      },
      {
        "value": "Right Wrist",
        "label": "Wrist",
        "labelTranslationId": "R_ANT055",
        "highlightRegion": "Hand-Wrist",
        "lineAnchor": [472, 390]
      },
      {
        "value": "Right Thumb",
        "label": "Thumb",
        "labelTranslationId": "R_ANT057",
        "highlightRegion": "Hand-Thumb",
        "lineAnchor": [630, 277]
      },
      {
        "value": "Right Index Finger",
        "label": "Index Finger",
        "labelTranslationId": "R_ANT058",
        "highlightRegion": "Hand-IndexFinger",
        "lineAnchor": [533, 141]
      },
      {
        "value": "Right Middle Finger",
        "label": "Middle Finger",
        "labelTranslationId": "R_ANT059",
        "highlightRegion": "Hand-MiddleFiner",
        "lineAnchor": [482, 125]
      },
      {
        "value": "Right Ring Finger",
        "label": "Ring Finger",
        "labelTranslationId": "R_ANT060",
        "highlightRegion": "Hand-RingFinger",
        "lineAnchor": [435, 144]
      },
      {
        "value": "Right Pinky Finger",
        "label": "Pinky Finger",
        "labelTranslationId": "R_ANT061",
        "highlightRegion": "Hand-PinkyFinger",
        "lineAnchor": [375, 197]
      },
      // {
      //   "value": "Back of Hand",
      //   "label": "Back of Hand",
      //   "labelTranslationId": "R_ANT062"
      // }
    ]
  },
  "left leg": {
    "isRoot": false,
    "isLeaf": true,
    "imgUrl": "view-assets/rule-of-3/anatomical_model_legLeft_{SEX}.svg",
    "allHighlightRegionId": ["Leg-FrontLeft", "Leg-BackLeft"],
    "choices": [
      {
        "value": "Left Thigh",
        "label": "Thigh",
        "labelTranslationId": "R_ANT067",
        "highlightRegion": "Leg-ThighLeft",
        "lineAnchor": [421, 360]
      },
      {
        "value": "Left Hamstring",
        "label": "Hamstring",
        "labelTranslationId": "R_ANT069",
        "highlightRegion": "Leg-HamstringLeft",
        "lineAnchor": [598, 363]
      },
      {
        "value": "Left Knee",
        "label": "Knee",
        "labelTranslationId": "R_ANT070",
        "highlightRegion": "Leg-KneeLeft",
        "lineAnchor": [412, 497]
      },
      {
        "value": "Left Calf",
        "label": "Calf",
        "labelTranslationId": "R_ANT071",
        "highlightRegion": "Leg-CalveLeft",
        "lineAnchor": [602, 571]
      },
      {
        "value": "left Shin",
        "label": "Shin",
        "labelTranslationId": "R_ANT072",
        "highlightRegion": "Leg-LowerLegLeft",
        "lineAnchor": [404, 635]
      },
      // {
      //   "value": "Left Ankle",
      //   "label": "Ankle",
      //   "labelTranslationId": "R_ANT074",
      //   "highlightRegion": "Leg-AnkleLeft",
      //   "lineAnchor": [400, 708]
      // },
    ]
  },
  "right leg": {
    "isRoot": false,
    "isLeaf": true,
    "imgUrl": "view-assets/rule-of-3/anatomical_model_legRight_{SEX}.svg",
    "allHighlightRegionId": ["Leg-FrontRight", "Leg-BackRight"],
    "choices": [
      {
        "value": "Right Thigh",
        "label": "Thigh",
        "labelTranslationId": "R_ANT067",
        "highlightRegion": "Leg-ThighRight",
        "lineAnchor": [612, 389]
      },
      {
        "value": "Right Hamstring",
        "label": "Hamstring",
        "labelTranslationId": "R_ANT069",
        "highlightRegion": "Leg-HamstringRight",
        "lineAnchor": [400, 409]
      },
      {
        "value": "Right Knee",
        "label": "Knee",
        "labelTranslationId": "R_ANT070",
        "highlightRegion": "Leg-KneeRight",
        "lineAnchor": [607, 480]
      },
      {
        "value": "Right Calf",
        "label": "Calf",
        "labelTranslationId": "R_ANT071",
        "highlightRegion": "Leg-CalveRight",
        "lineAnchor": [409, 590]
      },
      {
        "value": "Right Shin",
        "label": "Shin",
        "labelTranslationId": "R_ANT072",
        "highlightRegion": "Leg-LowerLegRight",
        "lineAnchor": [602, 589]
      },
      // {
      //   "value": "Right Ankle",
      //   "label": "Ankle",
      //   "labelTranslationId": "R_ANT074",
      //   "highlightRegion": "Leg-AnkleRight",
      //   "lineAnchor": [404, 701]
      // },
    ]
  },
  "left foot": {
    "isRoot": false,
    "isLeaf": true,
    "imgUrl": "view-assets/rule-of-3/anatomical_model_foot.svg",
    "allHighlightRegionId": "Foot_2",
    "choices": [
      {
        "value": "Top of Left Foot",
        "label": "Top of Foot",
        "labelTranslationId": "R_ANT075",
        "highlightRegion": "Foot-Top",
        "lineAnchor": [469, 199]
      },
      {
        "value": "Left Ankle",
        "label": "Ankle",
        "labelTranslationId": "R_ANT074",
        "highlightRegion": "Foot-Ankle",
        "lineAnchor": [593, 148]
      },
      {
        "value": "Left Achilles Tendon",
        "label": "Achilles Tendon",
        "labelTranslationId": "R_ANT076",
        "highlightRegion": "Foot-Achilles",
        "lineAnchor": [646, 137]
      },
      {
        "value": "Left Arch",
        "label": "Arch",
        "labelTranslationId": "R_ANT077",
        "highlightRegion": "Foot-Arch",
        "lineAnchor": [532, 242]
      },
      {
        "value": "Left Heel",
        "label": "Heel",
        "labelTranslationId": "R_ANT128",
        "highlightRegion": "Foot-Heel",
        "lineAnchor": [637, 334]
      },
      {
        "value": "Left Side of Foot",
        "label": "Side of Foot",
        "labelTranslationId": "R_ANT126",
        "highlightRegion": "Foot-Side",
        "lineAnchor": [559, 393]
      },
      {
        "value": "Left Ball",
        "label": "Ball",
        "labelTranslationId": "R_ANT078",
        "highlightRegion": "Foot-Ball",
        "lineAnchor": [436, 363]
      },
      {
        "value": "Left Big toe",
        "label": "Big toe",
        "labelTranslationId": "R_ANT079",
        "highlightRegion": "Foot-BigToe",
        "lineAnchor": [366, 320]
      },
      {
        "value": "Left Second toe",
        "label": "Second toe",
        "labelTranslationId": "R_ANT080",
        "highlightRegion": "Foot-SecondToe",
        "lineAnchor": [372, 349]
      },
      {
        "value": "Left Middle toe",
        "label": "Middle toe",
        "labelTranslationId": "R_ANT081",
        "highlightRegion": "Foot-MiddleToe",
        "lineAnchor": [383, 372]
      },
      {
        "value": "Left Fore toe",
        "label": "Fore toe",
        "labelTranslationId": "R_ANT082",
        "highlightRegion": "Foot-ForeToe",
        "lineAnchor": [403, 394]
      },
      {
        "value": "Left Pinky toe",
        "label": "Pinky toe",
        "labelTranslationId": "R_ANT083",
        "highlightRegion": "Foot-PinkyToe",
        "lineAnchor": [429, 410]
      }
    ]
  },
  "right foot": {
    "isRoot": false,
    "isLeaf": true,
    "imgUrl": "view-assets/rule-of-3/anatomical_model_foot.svg",
    "allHighlightRegionId": "Foot_2",
    "choices": [
      {
        "value": "Top of Right Foot",
        "label": "Top of Foot",
        "labelTranslationId": "R_ANT075",
        "highlightRegion": "Foot-Top",
        "lineAnchor": [469, 199]
      },
      {
        "value": "Right Ankle",
        "label": "Ankle",
        "labelTranslationId": "R_ANT074",
        "highlightRegion": "Foot-Ankle",
        "lineAnchor": [593, 148]
      },
      {
        "value": "Right Achilles Tendon",
        "label": "Achilles Tendon",
        "labelTranslationId": "R_ANT076",
        "highlightRegion": "Foot-Achilles",
        "lineAnchor": [646, 137]
      },
      {
        "value": "Right Arch",
        "label": "Arch",
        "labelTranslationId": "R_ANT077",
        "highlightRegion": "Foot-Arch",
        "lineAnchor": [532, 242]
      },
      {
        "value": "Right Heel",
        "label": "Heel",
        "labelTranslationId": "R_ANT128",
        "highlightRegion": "Foot-Heel",
        "lineAnchor": [637, 334]
      },
      {
        "value": "Right Side of Foot",
        "label": "Side of Foot",
        "labelTranslationId": "R_ANT126",
        "highlightRegion": "Foot-Side",
        "lineAnchor": [559, 393]
      },
      {
        "value": "Right Ball",
        "label": "Ball",
        "labelTranslationId": "R_ANT078",
        "highlightRegion": "Foot-Ball",
        "lineAnchor": [436, 363]
      },
      {
        "value": "Right Big toe",
        "label": "Big toe",
        "labelTranslationId": "R_ANT079",
        "highlightRegion": "Foot-BigToe",
        "lineAnchor": [366, 320]
      },
      {
        "value": "Right Second toe",
        "label": "Second toe",
        "labelTranslationId": "R_ANT080",
        "highlightRegion": "Foot-SecondToe",
        "lineAnchor": [372, 349]
      },
      {
        "value": "Right Middle toe",
        "label": "Middle toe",
        "labelTranslationId": "R_ANT081",
        "highlightRegion": "Foot-MiddleToe",
        "lineAnchor": [383, 372]
      },
      {
        "value": "Right Fore toe",
        "label": "Fore toe",
        "labelTranslationId": "R_ANT082",
        "highlightRegion": "Foot-ForeToe",
        "lineAnchor": [403, 394]
      },
      {
        "value": "Right Pinky toe",
        "label": "Pinky toe",
        "labelTranslationId": "R_ANT083",
        "highlightRegion": "Foot-PinkyToe",
        "lineAnchor": [429, 410]
      }
    ]
  },
}
/*
let modelToSheetExport = "";
Object.entries(DEFAULT_HUMAN_MODEL_V2).forEach(([id, view], index) => {
  const viewData = [
    id,
    !!view.isRoot,
    !!view.isLeaf,
    view.imgUrl ?? ""
  ];
  modelToSheetExport += viewData.join("\t");
  view.choices.forEach((c, i) => {
    if (i > 0) {
      modelToSheetExport += viewData.map(x => "").join("\t");
    }
    const choiceData = [
      i,
      c.viewId,
      c.value,
      c.label,
      c.labelTranslationId,
      c.highlightRegion,
      c.lineAnchor ? JSON.stringify(c.lineAnchor) : ""
    ];
    modelToSheetExport += "\t" + choiceData.join("\t") + "\n";
  })
  if (view.choices.length === 0) {
    modelToSheetExport += "\n";
  }
});
console.warn(modelToSheetExport);
*/

const EmptyModel : AnatomicalModel = {"error": {imgUrl: "--", choices: [], isLeaf: true}};

export const PredefinedModels : Record<string, AnatomicalModel> = {
  "default_human": DEFAULT_HUMAN_MODEL_V2,
  "EMPTY": EmptyModel
};
