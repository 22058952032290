import { ComponentProps, PropsWithChildren } from "react";
import "./buttons.css";

interface CTAProps extends PropsWithChildren<ComponentProps<'button'>> {
  label?: string,
  light?: boolean
}

/**
 * CTA or Call-To-Action is a particular style of wide button designed to
 * contain an important prompt to the user, generally the dominant interaction
 * point of a page or its most primary button.
 */
export const CTA = ({label = "OK", children, light, className, ...props}: CTAProps) => {
  return (
    <button {...props} className={`call-to-action ${light ? 'type-b' : ''} ${className}`}>{children ?? label}</button>
  );
}

/** 
 * CircleButtons are smaller, round widget-like buttons designed to hold
 * a single icon, symbol, or image.
 * 
 * TODO: not yet refactored
 */
export const CircleButton = ({children, ...props}: PropsWithChildren<ComponentProps<'div'>>) => {
  return (
    <div className="round-button">{children}</div>
  )
}