import { NumberAndUnitType as NumberAndUnit } from "./measurements";


export enum CoreDataType {
  SingleChoiceText = "single choice text",
  MultiChoiceText = "multi choice text",
  CalendarDate = "calendar date",
  Timestamp = "timestamp",
  Number = "number",
  Integer = "integer",
  Measurement = "measurement",
  SingleChoiceAnatomy = "single choice anatomy",
  MultiChoiceAnatomy = "multi choice anatomy"
}

export type CoreDataTypeLabel = `${CoreDataType}`;

export type Indexed<T> = {choiceIndex: number} & T;
export type IndexedString = Indexed<{value: string | null}>;

export type WrappedOf<A> = {
  value: A
}
export type ValueAtom = IndexedString | NumberAndUnit | WrappedOf<string | null> | WrappedOf<number | null>;