// TODO: Add grouped text (e.g., demographic data, symptoms, reason for visit, etc, which can be further nested)
// TODO: Add labels for the different areas, and a Hr between demographic, rfv information and report body
// TODO: As per design, report label and values should be inline (no longer column separated, although report may be)
// TODO: Add logo and product name to header

import moment from "moment";
import { useMemo, useState } from "react";
import { EnglishNames, LanguageISO, SelectorAssets } from "../../../constants/locales";
import { Answer, AnswerSetSubmissionStatus, GeneralAnswer, MultiValuedAnswer, NonconformingValueKind, SpecialAnswerKeys } from "../../../models/answers";
import { CoreDataType } from "../../../models/core-data-types";
import { ResponseLayout } from "../../../models/layouts";
import { NumberAndUnitType } from "../../../models/measurements";
import { loopIndexedAnswerId, QuestionDefinition } from "../../../models/questions";
import { BaseUser, UserType } from "../../../models/users";
import { QuestionnaireDefinition } from "../../../store/slices/definitions";
import {
  _switch,
  capitalizeByWord,
  ordinals,
  snakeToLowercase
} from "../../../utils";
import { JSObjectDump } from "../../../utils/UtilComponents";

export const SimpleLabel = ({ label, question, style, answerId }: {
  label: string;
  question?: QuestionDefinition,
  style?: React.CSSProperties,
  answerId: string
}) => {
  return (
    <div className="report-label" style={style}>
      {label ??
        question?.reportLabel ??
        (question
          ? capitalizeByWord(snakeToLowercase(question?.id))
          : answerId
          ? `[${answerId}]`
          : "")}
    </div>
  );
};

function NonconformingValueEntries ({answer}: {answer?: GeneralAnswer}) {
  if (Array.isArray(answer?.nonconformingValues)) {
    return <ul className="nonconforming-list">
      {answer.nonconformingValues.map((ncv, i) => <li key={i} className={ncv.kind === NonconformingValueKind.UserDefined ? "user-defined" : "ncr-choice"}>{ncv.value}</li>)}
    </ul>
  } else {
    return null;
  }
}

export const AtomicAnswerReportEntry = ({
  answer,
  answerId,
  question,
  label,
  showEmpty,
}: {
  answer?: GeneralAnswer,
  answerId?: string,
  question?: QuestionDefinition,
  label?: string,
  showEmpty?: boolean
}) => {
  const hasContent =
    !!answer &&
    ((answer.nonconformingValues ?? []).length > 0 ||
    (answer.isMulti
      ? answer.values?.length > 0
      : !["", null, undefined].includes(answer.value?.value)));
  if (!hasContent) {
    if (showEmpty) {
      return (
        <>
          <SimpleLabel label={label} question={question} answerId={answerId} />
          <div className="report-entry-text" style={{ fontStyle: "italic" }}>
            --
          </div>
        </>
      );
    } else {
      console.log(`Skipping ${answerId}, no value`);
      return null;
    }
  }
  switch (answer?.coreType ?? question?.coreType) {
    case CoreDataType.Timestamp:
      return (
        <>
          <SimpleLabel label={label} question={question} answerId={answerId} />
          <div className="report-value-singular report-value-date">
            {moment(answer?.value?.value).format("YYYY-MM-DD [at] hh:mma")}
            <NonconformingValueEntries answer={answer}/>
          </div>
        </>
      );
    case CoreDataType.CalendarDate:
      return (
        <>
          <SimpleLabel label={label} question={question} answerId={answerId} />
          <div className="report-value-singular report-value-date">
            {moment(answer?.value?.value).format("YYYY-MM-DD")}
            <NonconformingValueEntries answer={answer}/>
          </div>
        </>
      );
    case CoreDataType.Measurement:
      return (
        <>
          <SimpleLabel label={label} question={question} answerId={answerId} />
          <div className="report-value-singular report-value-measurement">
          {(answer as MultiValuedAnswer<NumberAndUnitType>).values?.map((v, i) => 
            <>
              {i === 0 ? null : <span className="unit-separator">•</span>}
              <span className="unit-joined">{v.value} {v.unit}</span>
            </>
          )}
          <NonconformingValueEntries answer={answer}/>
          </div>
        </>
      )
    default:
      if (question?.layout === ResponseLayout.PseudoMeasurement) {
        return (
          <>
            <SimpleLabel label={label} question={question} answerId={answerId} />
            <div className="report-value-singular report-value-measurement">
            {(answer as MultiValuedAnswer<NumberAndUnitType>).values?.map((v, i) => 
              <>
                {i === 0 ? null : <span className="unit-separator">•</span>}
                <span className="unit-joined">{v.value} {v.unit}</span>
              </>
            )}
            <NonconformingValueEntries answer={answer}/>
            </div>
          </>
        )
      }
      if (question?.isMulti) {
        return (
          <>
            <SimpleLabel
              label={label}
              question={question}
              answerId={answerId}
            />
            <div className="report-value-multi">
              {answer?.values?.map((v) => (
                <p>{v?.value}</p>
              ))}
              <NonconformingValueEntries answer={answer}/>
            </div>
          </>
        );
      }
      return (
        <>
          <SimpleLabel label={label} question={question} answerId={answerId} />
          <div className="report-value-singular">
            <span className={question?.layout === ResponseLayout.ShortAnswer && answer?.value?.value?.length > 0 ? "user-defined" : ""}>{answer?.value?.value}</span>
            <NonconformingValueEntries answer={answer}/>
          </div>
        </>
      );
  }
};

export const TextOnlyReportEntry = ({
  text,
  label,
  noLabel,
  usePrimaryColor,
  customStyles,
  children,
}) => {
  if (noLabel) {
    return <div className="report-entry-full">{children ?? text}</div>;
  }
  return (
    <>
      <SimpleLabel
        label={label}
        style={{
          color: usePrimaryColor ? "var(--evergreen)" : "auto",
          ...customStyles,
        }}
      />
      <div className="report-entry-text">{children ?? text}</div>
    </>
  );
};


function EmergencyHardcodedReport(answers, structure, setEnabled) {
  const gridSetup = {
    display: "grid",
    gridTemplateColumns: "[left] 4fr [right] 4fr [end]",
    gridTemplateAreas: `"header header header" "main main main"`,
    columnGap: "10px",
    rowGap: "5px",
  };
  return (
    <div style={{ padding: "5px" }}>
      <div
        style={{
          opacity: 0.3,
          position: "absolute",
          top: 5,
          left: 0,
          width: "100%",
          textAlign: "center",
        }}
        onClick={() => setEnabled(false)}
      >
        Toggle Custom Template
      </div>
      <h3>ED Report</h3>
      <div style={{ ...gridSetup }}>
        <div
          style={{
            gridColumn: "left",
            minHeight: "10rem",
            padding: "1rem",
            borderRadius: "0.5rem",
            background: "rgba(255, 200, 200, 0.25)",
          }}
        >
          Top Left
        </div>
        <div
          style={{
            gridColumn: "right",
            minHeight: "10rem",
            padding: "1rem",
            borderRadius: "0.5rem",
            background: "rgba(200, 255, 200, 0.25)",
          }}
        >
          Top Right
        </div>
        <div
          style={{
            gridColumn: "left / end",
            minHeight: "20rem",
            padding: "1rem",
            borderRadius: "0.5rem",
            background: "rgba(200, 200, 255, 0.25)",
          }}
        >
          Bottom
        </div>
      </div>
    </div>
  );
}

export const RedesignedReport = ({
  answers,
  user,
  questionnaireDefinition,
  reportType,
  submissionStatus,
  audience,
  acceptAnswer,
  language
}: {
  answers: Record<string, GeneralAnswer>;
  questionnaireDefinition: QuestionnaireDefinition;
  user: BaseUser;
  reportType: string;
  audience?: "provider" | "patient" | "admin";
  language?: LanguageISO;
  submissionStatus?: AnswerSetSubmissionStatus,
  acceptAnswer?: (newAnswer: Answer<any>, key: string) => void,
}) => {
  const [allowCustomTemplates, setAllowCustomTemplates] = useState(true);
  const [forceDisplayAll, setForceDisplayAll] = useState<null | "answers" | "questions">("answers");
  function handleFDAChoice(event) {
    if (event.target.value === "answers") {
      setForceDisplayAll(event.target.value);
    } else if (event.target.value === "questions") {
      setForceDisplayAll(event.target.value);
    } else {
      setForceDisplayAll(null);
    }
  }
  const answerCount = answers ? Object.keys(answers).length : 0;

  // useChangeDebugging([questionnaireDefinition, answers, user, reportType, submissionStatus, audience, acceptAnswer]);

  const reportStructure = useMemo(() => {
    if (forceDisplayAll) {
      return {
        title: `All-${forceDisplayAll} Dump`,
        mainContent:
          forceDisplayAll === "questions"
            ? Object.keys(questionnaireDefinition.questions)
            : Object.keys(answers),
      };
    }
    if (
      !questionnaireDefinition ||
      !Array.isArray(questionnaireDefinition.reports) ||
      questionnaireDefinition.reports.length === 0
    ) {
      return {
        title: "Questionnaire configuration is missing",
        mainContent: [],
      };
    }

    let foundReport = null;
    if (!reportType) {
      foundReport = questionnaireDefinition.reports.find((r) => r.useForReview);
    } else {
      foundReport = questionnaireDefinition.reports.find(
        (r) => r.name === reportType
      );
    }

    if (foundReport) {
      return foundReport;
    } else {
      console.error(
        `Could not find report ${reportType || "designated for review"} among ${
          questionnaireDefinition.reports.length
        } options, using first entry`
      );
      return { mainContent: [], ...questionnaireDefinition.reports[0] };
    }
  }, [questionnaireDefinition, reportType, forceDisplayAll]);

  const KNOWN_TEMPLATES = new Map([["emergency_A", EmergencyHardcodedReport]]);
  if (allowCustomTemplates && KNOWN_TEMPLATES.has(reportStructure?.template)) {
    return KNOWN_TEMPLATES.get(reportStructure?.template)(
      answers,
      reportStructure,
      setAllowCustomTemplates
    );
  } else {
    return (
      <div className="report-grid" style={{ padding: "5px",}}>
        {/* <div
          style={{
            opacity: 0.3,
            position: "absolute",
            top: 5,
            left: 0,
            width: "100%",
            textAlign: "center",
          }}
          onClick={() => setAllowCustomTemplates(true)}
        >
          Toggle Custom Template
        </div> */}
        {user.type === UserType.Superadmin ?
          <div
            style={{
              opacity: 0.3,
              position: "absolute",
              top: 10,
              left: 0,
              width: "100%",
              textAlign: "center",
            }}
          >
            <select
              style={{
                width: "320px",
                margin: "auto",
                border: "1px gray solid",
                borderRadius: 6,
                paddingLeft: 12,
              }}
              onChange={handleFDAChoice}
            >
              <option value={null}>Use report as defined</option>
              <option value={"answers"} selected>Generate entries for all answers</option>
              <option value={"questions"}>
                Generate entries for all questions
              </option>
            </select>
          </div>
        : null} 
        <div
          style={{
            gridArea: "header",
            borderBottom: "1px solid rgba(0,0,0,0.1)",
            paddingBottom: "5px",
            marginBottom: "10px",
          }}
        >
          <h3>{reportStructure.title}</h3>
          {audience === "provider" ? (
            <div style={{ float: "right" }}>
              <label style={{ fontWeight: "bold", marginRight: "2em" }}>
                Report ID
              </label>{" "}
              <span
                style={{
                  fontSize: "1.2rem",
                  borderRadius: 16,
                  padding: "4px 8px",
                  background: "rgba(0,0,0,0.01)",
                  boxShadow: "0 0 5px rgba(0,0,0,0.2)",
                }}
              >
                {answers?.[SpecialAnswerKeys.GeneratedReportID]?.value?.value ?? "----"}
              </span>
            </div>
          ) : null}
          <p>
            <em>
              <ul>
                <li>
                  {submissionStatus} by{" "}
                  <u>
                    {user.name ??
                      (user.firstName ?? "") + " " + (user.lastName ?? "")}
                  </u>
                </li>
                <li>{answerCount} answers</li>
                {language ? <li style={{lineHeight: 1.5}}>originally in {EnglishNames[language]}<sup>{SelectorAssets[language]?.flags}</sup></li> : null}
              </ul>
            </em>
          </p>
        </div>
        {reportStructure.mainContent
          .map((e) =>
            typeof e === "string" ? { type: "answer_atom", answerId: e } : e
          )
          .map((e) =>
            _switch(
              e.type,
              "text",
              <TextOnlyReportEntry {...e} />,
              "answer_atom",
              <AtomicAnswerReportEntry
                {...e}
                question={questionnaireDefinition.questions?.[e.answerId]}
                answer={answers[e.answerId]}
                showEmpty={forceDisplayAll}
              />,
              <JSObjectDump obj={e} />
            )
          )}
      </div>
    );
  }
};

export const LoopSummaryPage = ({
  loopId,
  loopLength,
  page,
  questionnaire,
  answers,
  createEntry,
  removeEntry,
  jumpToEntry,
}) => {
  const [confirmingRemoval, setConfirmingRemoval] = useState(null);

  const loopDef = useMemo(() => {
    return questionnaire?.loops?.[loopId];
  }, [loopId, questionnaire]);

  const loopQuestions = useMemo(() => {
    if (!loopId || !Array.isArray(questionnaire?.pages)) return [];
    return questionnaire.pages
      .filter((p) => p.loopId === loopId && p.type === "question")
      .flatMap((p) => p.questions);
  }, [loopId, questionnaire]);

  const iterationsWithAnswers = useMemo(() => {
    const iterHasAnswer = new Set();
    loopQuestions.forEach((qId) => {
      for (let i = 0; i < loopLength; i++) {
        const answer = answers[loopIndexedAnswerId(qId, i)];
        if (answer?.value?.value || answer?.values?.length) {
          iterHasAnswer.add(i);
        }
      }
    });
    return ordinals(loopLength).map((i) => iterHasAnswer.has(i));
  }, [answers, loopQuestions, loopLength]);

  const summaryImages = useMemo(() => {
    if (loopDef.summaryImageKey) {
      return ordinals(loopLength).map((i) => {
        const aID = `${loopDef.summaryImageKey}§${i}`;
        if (aID in answers) {
          const index = answers[aID].value?.choiceIndex;
          if (Number.isSafeInteger(index)) {
            if (answers[aID].isExclusionary) {
              return questionnaire.questions[loopDef.summaryImageKey]
                ?.nonconformingResponses[index]?.imgUrl;
            }
            return questionnaire.questions[loopDef.summaryImageKey]?.choices[
              index
            ]?.imgUrl;
          }
        }
        return null;
      });
    } else {
      return [];
    }
  }, [answers, loopDef, loopLength]);

  return (
    <div className="report-grid" style={{ width: "100%" }}>
      <div className="report-entry-full">
        <h3>{page.text}</h3>
      </div>
      {loopLength > 0 ? (
        ordinals(loopLength).map((i) => (
          <>
            <TextOnlyReportEntry
              label={
                loopDef.unit
                  ? loopDef.unit.replace("<N>", i + 1)
                  : `Entry ${i + 1}`
              }
              usePrimaryColor={true}
              customStyles={{ paddingLeft: "15px" }}
            >
              {loopDef.allowDeletion ? (
                confirmingRemoval === i ? (
                  <button
                    className="btn btn-danger btn-inline float-right"
                    onClick={() => {
                      removeEntry(i);
                      setConfirmingRemoval(null);
                    }}
                  >
                    Confirm Removal?
                  </button>
                ) : (
                  <button
                    className="btn btn-inline float-right"
                    onClick={() => setConfirmingRemoval(i)}
                  >
                    Remove
                  </button>
                )
              ) : null}
              {loopDef.allowJumps ? (
                <button
                  className="btn btn-inline float-right"
                  onClick={() => jumpToEntry(i)}
                >
                  Edit
                </button>
              ) : null}
            </TextOnlyReportEntry>
            {summaryImages?.[i] ? (
              <img
                style={{ gridColumn: "values", maxWidth: "200px" }}
                src={summaryImages?.[i]}
              />
            ) : null}
            {iterationsWithAnswers[i] ? (
              loopQuestions.map((qid) => (
                <AtomicAnswerReportEntry
                  key={`${qid}§${i}`}
                  answerId={`${qid}§${i}`}
                  question={questionnaire.questions?.[qid]}
                  answer={answers[`${qid}§${i}`]}
                />
              ))
            ) : (
              <div>
                <em>No answers in this loop yet</em>
              </div>
            )}
          </>
        ))
      ) : (
        <TextOnlyReportEntry
          label={
            loopDef.unit
              ? `No ${loopDef.unit.replace(/<N>|#/g, "").toLocaleLowerCase()}`
              : `No entries`
          }
          usePrimaryColor={true}
          customStyles={{ paddingLeft: "15px" }}
        />
      )}
      {loopDef.type === "user-extensible" ? (
        <button
          className="btn btn-primary report-entry-full"
          onClick={createEntry}
        >
          {page?.addEntryLabel ?? "Add Another Entry"}
        </button>
      ) : null}
      <em className="report-entry-text" style={{ opacity: 0.5 }}>
        (This loop is of type <tt>{loopDef.type}</tt>)
      </em>
    </div>
  );
};
