import { AudioSequence } from "../models/audio";
import { ResponseLayout } from "../models/layouts";

// audio play defaults
export const DefaultInterTrackAudioGap = 200; // 0.2 sec
export const DefaultSequenceLoopAudioGap = 5000; // 5 sec
export const DefaultAudioSequence: Partial<AudioSequence> = {
  id: `default-sequence`,
  files: [],
  current: 0,
  loop: false,
  gap: DefaultInterTrackAudioGap,
  loopGap: DefaultSequenceLoopAudioGap
};

// patient flow settings
export const ALLOW_BACKDROP_TO_CLOSE_MODAL = true;
export const AUTO_ADVANCE_TIMEOUT = 1200;
export const COMPLEX_UI_TYPES: ResponseLayout[] = [];
export const AUTO_ADVANCE_TYPES: ResponseLayout[] = [
  ResponseLayout.GridCards,
  ResponseLayout.StackCards,
];

export const TransparentOnePixelGif = "data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==";