import { Language, LanguageISO } from "../constants/locales";
import i18n from "../i18n";
import { NonconformingValueKind } from "./answers";
import { ResponseLayout } from "./layouts";
import {
  isChoiceLike,
  NonconformingResponseChoice,
  QuestionDefinition,
  ResponseChoice,
} from "./questions";

export type AudioLocation = { filename: string; isLocal: boolean };

/**
 * Metadata about an audio track that may or may not be currently playing.
 */
export interface AudioInfo {
  /**
   * The symbolic URI of this audio media; this is checked as a key against the
   * map of "true" audio locations that have been registered in the slice.
   */
  filename: string;
  /**
   * NOT YET IMPLEMENTED
   */
  translationId?: string;
  /**
   * The actual URL that can be used to retrieve the media file.
   */
  src?: string;
  /**
   * Whether the file is actively playing, as detected by audio element events.
   */
  playing?: boolean;
  /**
   * The general state of attempts to play this file.
   */
  status?: "loading" | "error" | "ok";
  /**
   * Error(s) that occurred while trying to request the audio resource.
   */
  requestError?: any;
  /**
   * Whether this file was queued as part of an AudioSequence.
   */
  isInSequence?: boolean;
}

export interface AudioSequence {
  /**
   * Unique name for this sequence, used to distinguish if actions are intended
   * to target it or another (potentially outdated) sequence. This is important
   * since we use timeouts to advance between tracks, so the actions may have
   * been scheduled during a different sequence.
   */
  id: string;
  /**
   * The list of filenames representing the tracks of this sequence.
   */
  files: string[];
  /**
   * Index of the last position within the sequence.
   */
  current: number;
  /**
   * Whether the sequence should loop back to the start or play only once.
   */
  loop: boolean;
  /**
   * The number of milliseconds from when one track finishes until the next
   * should start. (This is never perfectly accurate for various reasons.)
   */
  gap: number;
  /**
   * The number of milliseconds from when the last track finishes until the
   * first track should play again; only relevant if loop == true. (playSequence
   * will initialize this to be the same as gap, if not provided.)
   */
  loopGap: number;
  /**
   * The browser's numeric identifier for the last timeout scheduled for this
   * sequence, useful if you want to clear the timeout early, such as when audio
   * is terminated.
   */
  timerId?: number;
}

export const AUDIO_ROOT = `/audio/test_batch/`;
export const AUDIO_DEFAULT_FILETYPE = "mp3";

export function getAudioLocationFromId(
  translationId: string,
  langOverride?: LanguageISO
): AudioLocation {
  const lang = langOverride ?? i18n.language;
  const langSuffix = lang === Language.English ? "" : `_${lang}`;
  return {
    filename: `${AUDIO_ROOT}${translationId}${langSuffix}.${AUDIO_DEFAULT_FILETYPE}`,
    isLocal: true,
  };
}
export function getAudioLocationFromIdOrNull(
  translationId?: string
): AudioLocation | null {
  if (translationId) {
    return getAudioLocationFromId(translationId);
  }
  return null;
}

export enum KeyTranslationIDs {
  AllOfTheAbove = "R_GEN284",
  Unsure = "R_GEN001",
  Placeholder_FreeResponse = "I_GEN001",

  Instructions_Number = "INS004",
  Instructions_Calendar = "INS006",
  Instructions_Unsure = "INS007",
  Instructions_FreeResponse = "INS008",
  Instructions_FreeResponseOther = "INS009",
  Instructions_SelectLanguage = "P_GEN011",
  Instructions_TouchHereForLANG = "P_GEN015",

  Success_Title = "I_GEN053",
  Success_Description = "I_GEN054",
  Success_FeedbackPrompt = "Q_GEN048",
  Success_FeedbackSatisfied = "R_GEN309",
  Success_FeedbackNeutral = "R_GEN310",
  Success_FeedbackDissatisfied = "R_GEN311",
  Success_FeedbackCompletion = "R_GEN312",

  ConsentFormWhole = "consent",
}

export function getLanguageEntryAudioFilename(l: LanguageISO): string {
  return `${AUDIO_ROOT}${KeyTranslationIDs.Instructions_TouchHereForLANG}${
    l !== Language.English ? "_" + l : ""
  }.${AUDIO_DEFAULT_FILETYPE}`;
}

/**
 * This is where we manually set the translationIds for instructional audio
 * used when building autoplay sequences for various question types.
 */
export function defaultInstructionalAudioForType(
  layout: ResponseLayout | "!! language selector"
): string | null {
  switch (layout) {
    case ResponseLayout.Dropdown:
    case ResponseLayout.GridCards:
    case ResponseLayout.StackCards:
      return null;
    case ResponseLayout.AnatomicalRegion:
      return null;
    case ResponseLayout.Calendar:
      return KeyTranslationIDs.Instructions_Calendar;
    case ResponseLayout.ShortAnswer:
      return KeyTranslationIDs.Instructions_FreeResponse;
    case ResponseLayout.Measurement:
    case ResponseLayout.Numeric:
      return "INS001";
    case ResponseLayout.Empty:
      return null;
    case "!! language selector":
      return KeyTranslationIDs.Instructions_SelectLanguage;
    default:
      return null;
  }
}

export function getInstructionalAudio(
  q: QuestionDefinition
): AudioLocation | null {
  const translationId =
    q.instructionalTranslationId ?? defaultInstructionalAudioForType(q.layout);
  if (!translationId) return null;
  return getAudioLocationFromId(translationId);
}

export function getBasicUIAudio(element: "next button"): AudioLocation {
  switch (element) {
    case "next button":
      return { filename: "--NEXT_AUDIO_MISSING--", isLocal: true };
  }
}

/**
 * Create an AudioSequence to narrate the elements of this page
 */
export function buildAudioLocationListForQuestion(
  q: QuestionDefinition | undefined,
  hiddenChoices: number[] = []
): AudioLocation[] {
  if (!q) return [];

  const locations: AudioLocation[] = [];

  // 1) the prompt audio
  if (q.translationId) {
    locations.push(getAudioLocationFromId(q.translationId));
  }
  if (q.subtextTranslationId) {
    locations.push(getAudioLocationFromId(q.subtextTranslationId));
  }

  // 2) the "instructions" for each response type
  const instructions = getInstructionalAudio(q);
  if (instructions) {
    locations.push(instructions);
  }

  // 3a) the audio for each choice
  if (isChoiceLike(q)) {
    q.choices.forEach((c: ResponseChoice, i: number) => {
      // skip all the choices that are not visible
      if (hiddenChoices.includes(i)) {
        return;
      }
      if (c.labelTranslationId) {
        locations.push(getAudioLocationFromId(c.labelTranslationId));
      }
      if (c.descriptionTranslationId) {
        locations.push(getAudioLocationFromId(c.descriptionTranslationId));
      }
    });
  }

  if (Array.isArray(q.nonconformingResponses)) {
    q.nonconformingResponses.forEach(
      (c: NonconformingResponseChoice, i: number) => {
        // hiddenChoices uses negative numbers to represent nonconforming choices,
        // again we skip those that are currently disabled
        if (hiddenChoices.includes(-i)) {
          return;
        }
        if (c.instructionalTranslationId) {
          locations.push(getAudioLocationFromId(c.instructionalTranslationId));
        } else if (c.kind === NonconformingValueKind.UserDefined) {
          locations.push(
            getAudioLocationFromId(
              KeyTranslationIDs.Instructions_FreeResponseOther
            )
          );
        } else {
          // Note that we don't play label or description audio if we had any
          // instructional audio cases above. Generally those should already
          // contain reference to the choice's label, if any.
          if (c.labelTranslationId) {
            locations.push(getAudioLocationFromId(c.labelTranslationId));
          }
          if (c.descriptionTranslationId) {
            locations.push(getAudioLocationFromId(c.descriptionTranslationId));
          }
        }
      }
    );
  }

  // 3b) any audio elements for non-choice-like responses (TODO LATER!)

  // submit
  return locations;
}
