import { useEffect, useMemo, useState } from "react";
import { randHexPair } from "../../../utils";
import { useAppSelector } from "../../../utils/hooks";
import { determineSource } from "../../UI-old/DynamicImage";

export const ImageWithFallback = ({
  src,
  style,
  alt,
  useGradientForEmpty = null,
  ...props
}) => {
  const [fallback, setFallback] = useState(useGradientForEmpty);
  const bodyType = useAppSelector((s) => s.questionnaire.bodyTypePreference);
  const source = useMemo(() => determineSource(src, bodyType), [src, bodyType]);

  useEffect(() => {
    setFallback(useGradientForEmpty);
  }, [src, bodyType]);

  function imageFailed(event) {
    console.warn(`Couldn't display image '${source}'`, event);
    setFallback(true);
  }

  // We use a very simple placeholder generation algorithm, where we make a
  // smooth CSS linear gradient between two random light (>75%) colors.
  const colorA = `#${randHexPair(192)}${randHexPair(192)}${randHexPair(192)}`;
  const colorB = `#${randHexPair(192)}${randHexPair(192)}${randHexPair(192)}`;
  const angle = `${Math.floor(Math.random() * 360)}deg`;
  const [gradient] = useState(
    `linear-gradient(${angle}, ${colorA} 0%, ${colorB} 100%)`
  );

  return (
    <img
      src={fallback ? "/images/Transparent1x1.gif" : source}
      alt={fallback ? `gradient texture for a broken image (${alt})` : alt}
      onError={(e) => imageFailed(e)}
      style={
        fallback
          ? {
              background: gradient,
              minHeight: "50px",
              minWidth: "50px",
              ...style,
            }
          : style
      }
      {...props}
    />
  );
};
