import React, { useMemo } from 'react';

const Slider = ({ questionDefinition, answer, updateAnswer }) => {

  const handleChange = (event) => {
    updateAnswer({
      // id: questionDefinition.id, 
      isMulti: false, 
      value: {
        ...answer, 
        value: parseInt(event.target.value)
      }
    })
  };

  const [min, max] = useMemo(() => {
    return [questionDefinition?.min ?? 0, questionDefinition?.max ?? 10];
  })
  const widthOfTearDrop = 20;
  const xDistToMaximalSliderInputExtent = 30;
  const markerPosition = `calc(${
    ((answer.value?.value - min) / (max - min)) * 100
  }% - ${widthOfTearDrop/2}px)`;

  return (
    <div className="slider-container">
      <div className="image-container">
        <img src="/images/patient_redesign/chocolate.png" alt="temp img solution" height="150" />
      </div>
      <div className="w-100">
        <div className="marker-track" style={{margin: `0px ${xDistToMaximalSliderInputExtent}px 10px ${xDistToMaximalSliderInputExtent}px`}}>
          <div
              className="value-marker"
              style={{ width: `${widthOfTearDrop}px`, left: markerPosition }}>
            <div className="tear-drop" />
            <span className="value-text">{answer.value?.value}</span>
          </div>
        </div>
      </div>
      <input
        type="range"
        min={min}
        max={max}
        value={answer.value?.value || min}
        onChange={handleChange}
        className="slider"
      />
      <div className="slider-labels">
        <span>{min}</span>
        <span>{max}</span>
      </div>
    </div>
  );
};

export default Slider;

// todo: would love to implement a dynamic image
// src={`images/icecream_${value}.png`}
// alt={`Ice Cream ${value} Scoops`}