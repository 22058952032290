export const POSITIVES = ["y", "yes", "true", "1"];
export const NEGATIVES = ["n", "no", "false", "0"];
export const SPECIAL_QUERY = {
  assetcheck: {
    tracking: true,
    navlogic: false,
    autoprogress: false,
    nodisableprogress: true,
    duallang: true,
  },
};
