import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { KeyTranslationIDs } from "../../../models/audio";

export default function FreeTextResponse({
  answer,
  questionDefinition,
  updateAnswer,
}) {
  const {t} = useTranslation();
  function handleUserInput(event) {
    // call updateAnswer here with value: event.target.value and other things set as in ListQuestion
    updateAnswer({
      // id: questionDefinition.id,
      isMulti: false,
      value: {
        ...answer,
        value: event.target.value,
      },
    });
  }

  return (
    <div
      className="main-column"
      style={{
        margin: "0 auto",
        padding: questionDefinition.nonconformingResponses ? "" : "",
      }}
    >
      <textarea
        className={
          questionDefinition.nonconformingResponses
            ? "stack-height"
            : "full-height"
        }
        value={answer.value?.value}
        onChange={handleUserInput}
        placeholder={t(questionDefinition.placeholderTranslationId, questionDefinition.placeholder) || t(KeyTranslationIDs.Placeholder_FreeResponse, "Type your answer here")}
      />
    </div>
  );
}
