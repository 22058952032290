import { PageType } from "./pages";

export enum MetricsEvent {
  AppLaunch = "app/launch",
  Login = "session/login",
  Logout = "session/logout",
  ViewDashboardReport = "provider/view-report-from-dashboard",
  StartKioskMode = "session/switch-to-kiosk",
  KioskSelectLanguage = "kiosk/language",
  KioskProvideConsent = "kiosk/consent",
  PageForward = "page/advance",
  PageBackward = "page/backtrack",
  PageJump = "page/jump",
  AnswerFirst = "answer/first-interaction",
  AnswerLast = "answer/last-interaction",
  PlayAudio = "question/play-audio",
  ToggleAudio = "question/toggle-audio-autoplay",
  GetMoreInfo = "question/more-info",
  SubmitFeedback = "feedback/submit",
  KioskComplete = "kiosk/complete-patient",
  KioskStartOver = "kiosk/reset-at-end",
  KioskEarlyReset = "kiosk/reset-early",
  EndSession = "session/end",
  TabInactive = "session/tab-inactive",
  TabActive = "session/tab-active"
}

export interface MetricsEventData {
  type: MetricsEvent;
  timestamp: number;
}

export interface MetricsSession {
  initialTimestamp: number;
  sessionId: string;
  events: MetricsEventData[];
}

export interface PageEventData extends MetricsEventData {
  fromPage: number;
  fromPageType: PageType;
  toPage: number;
  toPageType: PageType;
  navSkipped: number[];
  toPageQuestions: string[];
}

export interface AnswerEventData extends MetricsEventData {
  question: string;
}
