import React from "react";


export const SmallModalDialog = (
  {
    quit,
    children,
    background
  }:
  {
    quit: () => void,
    children: React.ReactNode
    background?: 'green' | 'white'
  }
) => {
  return (
    <div
      style={{
        position: "absolute",
        left: 0,
        top: 0,
        width: "100vw",
        height: "100vh",
        background: "rgba(256, 256, 256, 0.8)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 100,
      }}
      onClick={quit}
    >
      <div
        className="timer-dialog"
        style={{
          background: background === 'green' ? "var(--evergreen)" : "var(--pure-white)",
          boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.2)",
          borderRadius: "20px",
          marginLeft: "20px",
          marginRight: "20px",
          maxWidth: "100vw",
          width: "500px",
          textAlign: "center",
          padding: "1em",
        }}
        onClick={(e) => e.stopPropagation()}
      >
      {children}
      </div>
    </div>)
    ;
}