import firebase from "firebase";

export interface IDed {
  id: string;
}

/**
 * A flag for the current status of a loadable resource.
 */
export enum LoadState {
  /**
   * Loading has not been initiated
   */
  Unstarted = 0,
  /**
   * The resource is actively loading
   */
  Loading = 1,
  /**
   * The resource is not currently loading, but has started and not yet failed
   */
  Halted = 2,
  /**
   * The load was successful and has finished. This makes no statement about the
   * validity of the retrieved resource (for example, JSON that doesn't comply
   * with its spec, or an HTTP error that still returned a 200+body)
   */
  Complete = 8,
  /**
   * Generic failure.
   * NOTE: All values 16 or higher are assumed to be failure states!
   */
  Failed_Unspecified = 16,
  Failed_TooLarge = 17,
  Failed_TooLong = 18,
  Failed_NotFound = 19,
  Failed_InvalidRequest = 20,
  Failed_ServerError = 21
}

export function isProcessing (o: WithLoadStatus): boolean {
  return o.loadState === LoadState.Loading || o.loadState === LoadState.Halted;
}
export function hasFailed (o: WithLoadStatus): boolean {
  return o.loadState >= LoadState.Failed_Unspecified;
}
export function initialLoadStatus () : WithLoadStatus {
  return ({loadState: LoadState.Unstarted, loadTime: Number.MAX_SAFE_INTEGER, ready: false});
}

export interface WithLoadStatus {
  loadState: LoadState;
  loadTime: number;
  ready: boolean;
}

export function unwrapSingularSnapshotForThunk<T extends object>(
  response: firebase.firestore.DocumentSnapshot<T>,
  typeLabel: string = "document"
): T & IDed {
  if (!response.exists) {
    throw new Error(`Could not find ${typeLabel} for ID ${response.id}`);
  }
  const data: T | undefined = response.data();
  if (data === undefined) {
    throw new Error(`Data for ${typeLabel} ${response.id} was undefined`);
  }
  return { ...data, id: response.id };
}

export function unwrapQuerySnapshotForThunk<T extends object>(
  response: firebase.firestore.QuerySnapshot<T>,
  requireAtLeastOne: boolean = false,
  typeLabel: string = "document"
): Array<T & IDed> {
  if (requireAtLeastOne && response.empty) {
    throw new Error(
      `Could not find any ${typeLabel} for query ${response.query}`
    );
  }
  return response.docs.map(
    (doc: firebase.firestore.QueryDocumentSnapshot<T>) => ({
      ...doc.data(),
      id: doc.id,
    })
  );
}
