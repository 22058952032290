import { useState } from "react";
import { IS_PROD } from "../../constants/app";
import { authSlice, Credentials } from "../../store/slices/auth";
import {
  useAppDispatch,
  useAppSelector,
  useBooleanState,
} from "../../utils/hooks";
import { JSObjectDump } from "../../utils/UtilComponents";
import { CTA } from "../UI/buttons/CTA";

export type BuildAnnotatedWindow = {
  __BUILD: Record<"time" | "hash" | "dirty" | "branch", string>;
} & typeof window;

export const LoginComponent = ({
  actionOverride,
}: {
  actionOverride?: (c: Credentials) => void;
}) => {
  const dispatch = useAppDispatch();

  const { value: showBuildDetails, toggle: toggleBuildDetails } =
    useBooleanState(false);

  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const user = useAppSelector((s) => s.auth.user);
  const authProcessing = useAppSelector((s) => s.auth.loading);
  const authError = useAppSelector((s) => s.auth.error);

  function tryLogin(e: Event) {
    if (actionOverride && typeof actionOverride == "function") {
      actionOverride({ username, password });
    } else {
      dispatch(authSlice.actions.login({ username, password }));
    }
    e.preventDefault();
    return false;
  }

  return (
    <div
      className="centered-flex-container main-columm force-full-height"
      style={{ justifyContent: "center", gap: 20 }}
    >
      {IS_PROD ? null : (
        <div
          onClick={toggleBuildDetails}
          style={{
            position: "absolute",
            top: 15,
            padding: "12px 24px",
            textAlign: "center",
            backgroundColor: "#ffdc26",
          }}
          className="raised-box"
        >
          This is NOT the{" "}
          <a href="https://app.literaseed.io">production application</a>. Do not
          use real data!{" "}
          {showBuildDetails ? "" : <em>(Click for build details.)</em>}
          {showBuildDetails ? (
            <JSObjectDump
              obj={(window as BuildAnnotatedWindow).__BUILD}
              style={{ textAlign: "left" }}
            />
          ) : null}
        </div>
      )}
      <div className="logo-bar">
        <div className="raised-box logo">
          <img src="/images/login_logo_literaseed.png" />
        </div>
        <div className="raised-box logo">
          <img src="/images/login_logo_valleywise.png" />
        </div>
      </div>
      <div className="raised-box">
        <form onSubmit={tryLogin}>
          <h4>
            Welcome to the <u>Valleywise Emergency Department</u> portal for
            LiteraSeed.
          </h4>
          <p>
            This section is for providers and staff of the above organization.
            Please log in below. For login issues, contact LiteraSeed directly.
          </p>
          <hr style={{ marginBottom: "2em" }} />
          <div>
            <label>Username/Email</label>
            <input
              type="email"
              placeholder="email"
              onChange={(e) => setUsername(e.target.value)}
              autoCapitalize="none"
            ></input>
          </div>
          <div style={{ marginTop: "0.5em" }}>
            <label>Password</label>
            <input
              type="password"
              placeholder="password"
              onChange={(e) => setPassword(e.target.value)}
              autoCapitalize="none"
            ></input>
          </div>
          <CTA
            label="Log In"
            style={{ width: "100%", marginTop: "2em" }}
            light={false}
            onClick={tryLogin}
            disabled={authProcessing}
            children={
              authProcessing ? (
                <div className="loader-dots-fade"/>
              ) : null
            }
          />
          {authError ? (
            <>
              <JSObjectDump obj={authError} style={{ background: "#FFE0E0" }} />
              <span style={{ color: "var(--salmon-red)" }}>
                If you are having difficulty with the app, you may want to{" "}
                <a
                  style={{ textDecoration: "underline" }}
                  onClick={() => window.location.reload()}
                >
                  reload
                </a>
              </span>
            </>
          ) : null}
        </form>
      </div>
    </div>
  );
};
