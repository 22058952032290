import { ComponentProps, createRef, useState } from "react";
import { isSelected, selectStandardChoice } from "../../../models/choices";
import { QuestionDefinition, ResponseChoice } from "../../../models/questions";
import { JSObjectDump } from "../../../utils/UtilComponents";
import { Card } from "../../UI/Card/Card";
import { GeneralAnswer } from "../../../models/answers";

export function ListChoiceCard({
  item,
  handleClick,
  isSelected,
  style = {},
  description,
}: {
  item: ResponseChoice;
  handleClick: (i: ResponseChoice, current?: boolean) => void;
  isSelected: boolean;
  description?: string;
} & ComponentProps<"div">) {
  return (
    <Card
      isSelected={isSelected}
      onClick={() => handleClick(item, isSelected)}
      stack={true}
      art={item.art}
      label={item.label}
      description={description ?? item.description}
      {...item}
    />
  );
}

export default function StackResponse({
  questionDefinition,
  answer,
  updateAnswer,
  hiddenChoices = [],
  showTrackingInfo = false,
  subtextOverrides,
}: {
  questionDefinition: QuestionDefinition,
  answer: GeneralAnswer,
  updateAnswer: (a: GeneralAnswer) => void,
  showTrackingInfo?: boolean;
  hiddenChoices?: number[];
  subtextOverrides?: string[];
}) {
  function toggleStandard(item: ResponseChoice, isCurrentlySelected: boolean) {
    const updatedAnswerObj = selectStandardChoice(
      item,
      questionDefinition,
      answer
      // { id: questionDefinition.id }
    );
    updateAnswer(updatedAnswerObj);
  }

  const responses = questionDefinition.choices.map((choice, index) => (
    <>
      {showTrackingInfo ||
      !hiddenChoices.includes(index) ||
      isSelected(index, questionDefinition, answer) ? (
        <ListChoiceCard
          key={choice.label}
          item={choice}
          description={subtextOverrides?.[index]}
          handleClick={toggleStandard}
          isSelected={isSelected(index, questionDefinition, answer)}
          style={hiddenChoices.includes(index) ? { opacity: 0.5 } : {}}
        />
      ) : null}
      {showTrackingInfo ? (
        <>
          {choice.displayWhen ? (
            <label>
              #{index} displayWhen: <JSObjectDump obj={choice.displayWhen} />
            </label>
          ) : null}
          {choice.skipWhen ? (
            <label>
              #{index} skipWhen: <JSObjectDump obj={choice.skipWhen} />
            </label>
          ) : null}
        </>
      ) : null}
    </>
  ));

  return (
    <div className="centered-flex-container main-column">
      <div className="stack-of-cards">{responses}</div>
    </div>
  );
}
