import { buildCreateSlice, asyncThunkCreator, ThunkAction, UnknownAction } from '@reduxjs/toolkit'
import type { RootState } from '../store/store'

/**
 * Create slice with thunks. Since we use this at least once, the thunk creator
 * is bundled into our app, so there's no penalty to using it for every slice,
 * even if that slice doesn't use thunks.
 * 
 * See https://redux-toolkit.js.org/usage/migrating-rtk-2 for historical details
 * and explanation of why we have to include this bit ourselves.
 */
export const createSliceWithThunks = buildCreateSlice({
  creators: { asyncThunk: asyncThunkCreator },
})

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  UnknownAction
>
