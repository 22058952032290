import { Language, LanguageISO, Region, RegionType } from "./locales";
import { IS_DEVELOPMENT } from "./app";

// TODO: convert to enums!
export enum ScreeningType {
  ED = "emergency",
  BECKSDEP = "becksdpr",
  ANXIETY = "anxiety",
  COVID = "covid",
  OBGYN = "obgyn",
  OBGYN_PLUS = "obgynplus",
  OB_PAK = "obpak",
  RESPINFECT = "respinfect",
  TEST_AND_DEBUG = "test~",
  ICE_CREAM_DEMO = "icecream"
}

// a mapped type to T, defines an object that must have a property for every
// screening type value in the enum, and whose values are the parameterized type
type ScreenerProperty<T> = {[Key in ScreeningType]: T};

/**
 * 
 */
export const ScreenerNames : ScreenerProperty<string> = {
  [ScreeningType.ED]: "Emergency Dept.",
  [ScreeningType.OBGYN]: "OB/GYN Pre-visit Interview",
  [ScreeningType.OBGYN_PLUS]: "New OB/GYN Extended Interview",
  [ScreeningType.OB_PAK]: "OB AKU Questionnaire",
  [ScreeningType.COVID]: "COVID-19 Risk Screener",
  [ScreeningType.RESPINFECT]: "Respiratory Infection",
  [ScreeningType.BECKSDEP]: "Depression Inventory",
  [ScreeningType.ANXIETY]: "Anxiety Inventory",
  [ScreeningType.TEST_AND_DEBUG]: "TESTING SCREENER",
  [ScreeningType.ICE_CREAM_DEMO]: "Sample ice cream shop ordering system"
}
export const ScreenerShortNames : ScreenerProperty<string> = {
  [ScreeningType.ED]: "Emergency",
  [ScreeningType.OBGYN]: "OB/GYN",
  [ScreeningType.OBGYN_PLUS]: "OB/GYN Extended",
  [ScreeningType.OB_PAK]: "OB",
  [ScreeningType.COVID]: "COVID-19",
  [ScreeningType.RESPINFECT]: "Resp. Infections",
  [ScreeningType.BECKSDEP]: "Depression",
  [ScreeningType.ANXIETY]: "Anxiety", 
  [ScreeningType.TEST_AND_DEBUG]: "TESTING",
  [ScreeningType.ICE_CREAM_DEMO]: "Ice Cream Time!"
}

/**
 * Check whether a particular screening type & language is allowed in the
 * current environment.
 * @param {string} screener 
 * @param {string} lang 
 * @returns {boolean}
 */
export function allows (screener: ScreeningType, lang: LanguageISO) {
  // This is deprecated, we're going to switch to a presence-of-file solution.
  return true;
}

export const FallbackLang = Language.English;

// The "Assignable" arrays determine which screeners are valid for enrollment
// in each environment type (a screener needs to be listed here AND in the org's
// whitelist in order to appear on the patient enrollment form, for example)
export const DevAssignable = [
  ScreeningType.ED,
  ScreeningType.OBGYN,
  ScreeningType.BECKSDEP,
  ScreeningType.ANXIETY,
  ScreeningType.COVID,
  ScreeningType.RESPINFECT,
  ScreeningType.OBGYN_PLUS,
  ScreeningType.OB_PAK,
  ScreeningType.TEST_AND_DEBUG,
  ScreeningType.ICE_CREAM_DEMO
];
export const AssumedV2Support = [
  ScreeningType.ED,
  ScreeningType.ICE_CREAM_DEMO
];
export const ProdAssignable = [
  ScreeningType.OBGYN,
  ScreeningType.OBGYN_PLUS,
  ScreeningType.OB_PAK,
  ScreeningType.BECKSDEP,
  ScreeningType.ANXIETY,
  ScreeningType.COVID,
  ScreeningType.ED
];

export const Assignable = IS_DEVELOPMENT ? DevAssignable : ProdAssignable;

export function hasDynamicBodyTypes (screener: ScreeningType) : boolean {
  return [ScreeningType.TEST_AND_DEBUG, ScreeningType.ED, ScreeningType.COVID].includes(screener);
}

export interface QuestionnaireLoadParams {
  type: ScreeningType,
  locale: {
    language: LanguageISO,
    region?: RegionType
  },
  version?: string
}
export type QuestionnaireIDString = string;
export type QuestionnaireKindString = string;

// questionnaire canonical id:
// <ScreeningType>_<Locale>_<VersionString>
// eventually replace version string with hash of json content?
export function generateQuestionnaireId (data: QuestionnaireLoadParams) : QuestionnaireIDString {
  throw Error("Unsupported; all entity IDs should use 'kind' instead of 'id'");
  // TODO: this will eventually incorporate a hash or become a hash itself. [tdhs]
  // For a version that will always incorporate type+locale use the Kind fn.
  return `${data.type}_${data.locale.language}-${data.locale.region || Region.World}_${data.version || "unversioned"}`;
}
export function generateQuestionnaireKind (data: QuestionnaireLoadParams) : QuestionnaireKindString {
  // temporary fix for any remaining old calls, remove once redux is 100% typed
  if ("screener" in data) {
    data.type = data.screener as ScreeningType;
  }
  return `${data.type}_${data.locale.language}`; // -${data.locale.region || Region.World}`;
}

// Aliases for filename lookups, useful for migrations in names or when two
// screeners are exactly the same / have yet to diverge.
export const FileAliases = new Map([
  // ["original_en.json", "replacement-file-to-load.json"]  // example entry
]);

