import { FaVolumeUp } from "react-icons/fa";
import { audioSlice } from "../../store/slices/audio";
import { useAppDispatch, useAppSelector } from "../../utils/hooks";
import React from "react";

const PlayStateColor = {
  ready: "black",
  playing: "green",
};

/**
 * The button used to toggle the audio on and off.
 * 
 * Note that since the current design ONLY displays this button as a part of the
 * navigational header, its behavior is set to only one of two things:
 * - when audio is "locked" to playing (as on the language selector) it can
 *   stop that audio with a stopAudio action
 * - in all other cases, it toggles the autoplay state on and off, which may
 *   queue or halt an active sequence depending on the content of the page
 * 
 * If we need this component in other locations, that behavior will need to be
 * softened and abstracted.
 */
export function AudioButton({
  lockPlaying,
  divProps = {},
  style = {},
}: {
  lockPlaying: boolean;
  divProps?: Partial<React.PropsWithoutRef<any>>;
  style?: React.CSSProperties
}) {
  const dispatch = useAppDispatch();
  const audioAutoplay = useAppSelector((s) => s.audio.autoplay);
  const anyAudioPlaying = useAppSelector((s) => s.audio.activeAudio.playing);
  const showAnimated = lockPlaying ? !!anyAudioPlaying : audioAutoplay;
  function clickAudio() {
    if (!lockPlaying) {
      dispatch(audioSlice.actions.toggleAutoplay());
    } else {
      dispatch(audioSlice.actions.stopAudio());
    }
  }
  return (
    <div
      className={`chrome-btn centered-btn ${
        showAnimated ? "pulsing" : ""
      }`}
      style={{
        float: "right",
        margin: "20px",
        pointerEvents: "visible",
        color:
          showAnimated
            ? PlayStateColor.playing
            : PlayStateColor.ready,
        ...style,
      }}
      onClick={clickAudio}
      {...divProps}
    >
      {/* <img src="/images/patient_redesign/speaker.svg" style={{display: "block"}}/> */}
      <FaVolumeUp />
    </div>
  );
}
