import { PayloadAction } from "@reduxjs/toolkit";
import { nanoid } from "nanoid";
import { useEffect } from "react";
import { firestoreCollections } from "../../database/collections";
import {
  AnswerEventData,
  MetricsEvent,
  MetricsEventData,
  MetricsSession,
} from "../../models/metrics";
import { unwrapSingularSnapshotForThunk } from "../../utils/database";
import { useAppDispatch } from "../../utils/hooks";
import { createSliceWithThunks } from "../../utils/redux";
import { answerSetSlice } from "./answerSets";
import { audioSlice } from "./audio";
import { authSlice } from "./auth";
import { patientFlowSlice } from "./patient-flow";
// import type { RootState } from "../store";

export interface MetricsState {
  sessionId: string;
  initialTimestamp: number;
  events: MetricsEventData[];
  lastEventTimestamp: number;
  lastSavedTimestamp: number;
  lastSavedIndex: number;
  sessions: Record<string, MetricsSession>;
  error: any;
}
const initialState: MetricsState = {
  sessionId: "INIT_" + nanoid(),
  initialTimestamp: Date.now(),
  lastEventTimestamp: -1,
  lastSavedTimestamp: -1,
  lastSavedIndex: -1,
  events: [],
  sessions: {},
  error: null,
};

// function eventRecorder (builder: ActionReducerMapBuilder<MetricsState>, actionCreator: any, state: Draft<MetricsState>, newEvent: MetricsEventData) {
//   state.events.push(newEvent);
// }

function basicEvent(type: MetricsEvent, props: any = {}): MetricsEventData {
  return { type, timestamp: Date.now(), ...props };
}
function recordEvent(state: MetricsState, event: MetricsEventData) {
  state.events.push(event);
  state.lastEventTimestamp = Date.now();
}

export const metricsSlice = createSliceWithThunks({
  name: "metrics",
  initialState,
  reducers: (create) => ({
    updateTabActivityState: create.reducer(
      (state, action: PayloadAction<{ active: boolean }>) => {
        if (action.payload.active === true) {
          state.events.push(basicEvent(MetricsEvent.TabActive));
        } else {
          state.events.push(basicEvent(MetricsEvent.TabInactive));
        }
      }
    ),

    startNewMetricsSession: create.asyncThunk(
      async (arg: void, thunkApi) => {
        const initialTimestamp = Date.now();
        const metricsDocRef = firestoreCollections.metrics.doc();
        const newSession: MetricsSession = {
          initialTimestamp,
          sessionId: metricsDocRef.id,
          events: [],
        };
        const firebaseResponse = await metricsDocRef.set(newSession);
        return { firebaseResponse, newSession };
      },
      {
        pending: (state) => {
          state.events = [];
        },
        rejected: (state, action) => {
          state.error = action.payload ?? action.error;
        },
        fulfilled: (state, action) => {
          state.initialTimestamp = action.payload.newSession.initialTimestamp;
          state.sessionId = action.payload.newSession.sessionId;
          state.events = [];
        },
      }
    ),

    saveMetricsSession: create.asyncThunk(
      async (arg: void, thunkAPI) => {
        const metricsState = (thunkAPI.getState() as any)
          .metrics as MetricsState;
        const firebaseResponse = await firestoreCollections.metrics
          .doc(metricsState.sessionId)
          .update({ events: metricsState.events });
        return { firebaseResponse, eventCount: metricsState.events.length };
      },
      {
        fulfilled: (state, action) => {
          state.lastSavedTimestamp = Date.now();
          state.lastSavedIndex = action.payload.eventCount;
        },
        rejected: (state, action) => {
          state.error = action.error || action.payload;
        },
      }
    ),

    saveOnQuitIfNeeded: create.asyncThunk(
      async (arg: void, thunkAPI) => {
        const metricsState = (thunkAPI.getState() as any)
          .metrics as MetricsState;
        if (metricsState.lastSavedIndex < metricsState.events.length) {
          const firebaseResponse = await firestoreCollections.metrics
            .doc(metricsState.sessionId)
            .update({ events: metricsState.events });
          return {
            firebaseResponse,
            eventCount: metricsState.events.length,
            unneeded: false,
          };
        } else {
          return { firebaseResponse: null, eventCount: 0, unneeded: true };
        }
      },
      {
        fulfilled: (state, action) => {
          // we might not even see this complete if we were closing the tab or
          // some other "rapid" quit state
          if (action.payload.unneeded) {
            console.log("No action taken, metrics were up-to-date");
          } else {
            state.lastSavedTimestamp = Date.now();
            state.lastSavedIndex = action.payload.eventCount;
          }
        },
        rejected: (state, action) => {
          state.error = action.error || action.payload;
        },
      }
    ),

    loadMetricsSession: create.asyncThunk(
      async (id: string, thunkAPI) => {
        const firebaseResponse = await firestoreCollections.metrics
          .doc(id)
          .get();
        return unwrapSingularSnapshotForThunk(firebaseResponse);
      },
      {
        fulfilled: (state, action) => {
          state.sessions[action.payload.id] = action.payload;
        },
      }
    ),

    /**
     * Manually add an event to the timeline. This action is necessary for any
     * metrics events that don't have associated Redux actions.
     */
    recordEvent: create.reducer(
      (
        state,
        action: PayloadAction<{
          type: MetricsEvent;
          addlProps?: Partial<MetricsEventData>;
        }>
      ) => {
        state.events.push(
          basicEvent(action.payload.type, action.payload.addlProps)
        );
      }
    ),
    /**
     * List of metrics that currently rely on recordEvent rather than "natural"
     * Redux actions. (TRY TO KEEP UPDATED SO THAT THIS FILE SHOWS ALL EVENTS)
     * ========================================================================
     *
     * ViewDashboardReport: not a separate db load from opening the dashboard,
     *                      so no other actions triggered on open
     * KioskProvideConsent: only implicitly assumed by progression; we don't
     *                      record anything in the DB when they click
     *  (all page nav events): temporary until reduxified!
     *
     * TODO:  GetMoreInfo
     */
  }),
  extraReducers: (builder) => {
    builder.addCase(authSlice.actions.login.fulfilled, (state, action) => {
      state.events.push(basicEvent(MetricsEvent.Login));
    });
    builder.addCase(authSlice.actions.logout.fulfilled, (state, action) => {
      state.events.push(basicEvent(MetricsEvent.Logout));
    });
    builder.addCase(
      authSlice.actions.startKioskMode.fulfilled,
      (state, action) => {
        state.events.push(basicEvent(MetricsEvent.StartKioskMode));
      }
    );
    builder.addCase(
      patientFlowSlice.actions.selectLanguage.fulfilled,
      (state, action) => {
        state.events.push(
          basicEvent(MetricsEvent.KioskSelectLanguage, {
            language: action.meta.arg,
          })
        );
      }
    );

    builder.addCase(patientFlowSlice.actions.acceptAnswers, (state, action) => {
      // TODO: I don't think the "first" and "last" model is going to last...
      action.payload.forEach(([answerKey, questionDefinition, answer]) => {
        const startEvent = state.events.find(
          (e) =>
            e.type === MetricsEvent.AnswerFirst &&
            (e as AnswerEventData).question === answerKey
        );
        if (startEvent) {
          const lastIndex = state.events.findIndex(
            (e) =>
              e.type === MetricsEvent.AnswerLast &&
              (e as AnswerEventData).question === answerKey
          );
          if (lastIndex === -1) {
            state.events.push(
              basicEvent(MetricsEvent.AnswerLast, { answerKey })
            );
          } else {
            state.events.splice(
              lastIndex,
              1,
              basicEvent(MetricsEvent.AnswerLast, { answerKey })
            );
          }
        } else {
          state.events.push(
            basicEvent(MetricsEvent.AnswerFirst, { answerKey })
          );
        }
      });
    });
    builder.addCase(audioSlice.actions.toggleAutoplay, (state, action) => {
      state.events.push(basicEvent(MetricsEvent.ToggleAudio));
    });
    builder.addCase(audioSlice.actions.playKnownAudio, (state, action) => {
      state.events.push(
        basicEvent(MetricsEvent.PlayAudio, { filename: action.payload })
      );
    });
    builder.addCase(
      answerSetSlice.actions.saveCurrentAnswerSet.fulfilled,
      (state, action) => {
        // TODO: this wouldn't actually distinguish between completion of kiosk
        // flow vs another reason we save the answer set, but it's fine for now
        state.events.push(basicEvent(MetricsEvent.KioskComplete));
      }
    );
    builder.addCase(patientFlowSlice.actions.clearAll, (state, action) => {
      if (action.payload.isEarly) {
        state.events.push(
          basicEvent(MetricsEvent.KioskEarlyReset, {
            reason: action.payload.reason,
          })
        );
      } else {
        state.events.push(
          basicEvent(MetricsEvent.KioskStartOver, {
            reason: action.payload.reason,
          })
        );
      }
    });
  },
});

/**
 * A simple hook for attaching tab activity events and linking with the slice
 * @param addlCallback A secondary callback to trigger
 */
export const useTabActivity = (addlCallback?: (active: boolean) => void) => {
  const dispatch = useAppDispatch();

  function handleVisibilityChange() {
    const active = document.visibilityState === "visible";
    dispatch(metricsSlice.actions.updateTabActivityState({ active }));
    if (addlCallback) {
      addlCallback(active);
    }
  }

  useEffect(() => {
    window.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      window.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);
};
