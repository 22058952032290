/**
 * This file defines the configuration and metadata for handling different measurement dimensions 
 * (e.g., length, weight) across metric and imperial systems. It provides details such as units, 
 * conversion factors, default values, and display preferences. These configurations enable consistent 
 * representation, conversion, and localization of measurements within the application.
 */

import { METRIC,
   IMPERIAL,
   ESTABLISHED_SYSTEMS,
   Dimension,
   SubdividedUnit,
   AtomicUnit,
   SystemOfMeasure,
   NumberAndUnitType
 } from "../models/measurements";

/* ---------------- Length ---------------- */
export const Length : Dimension = {
  // internal length is stored in pure inches
  [METRIC]: {
    unit: "cm",
    unitLong: "centimeters",
    unitTranslationIds: ["P_DMG009", "P_DMG007"],
    conversionFactor: 2.54,
    listExhaustively: true,
    max: 250,
    default: 160
  } as AtomicUnit,

  [IMPERIAL]: {
    isProviderPreferred: true,
    isTuple: true,
    unit: ["ft", "in"],
    unitLong: ["feet", "inches"],
    unitTranslationIds: [
      ["P_DMG010", "P_DMG002"],
      ["P_DMG011", "P_DMG003"]
    ],
    default: [4, 4],
    conversionFactor: [12.0, 1.0],
    max: [7, 11],
    min: [0, 0],
    listExhaustively: [true, true]
  } as SubdividedUnit
};

/* ---------------- Weight ---------------- */
export const Weight : Dimension = {
  [METRIC]: {
    unit: "kg",
    unitLong: "kilograms",
    unitTranslationIds: ["P_DMG013", "P_DMG008"],
    conversionFactor: 0.453592
  } as AtomicUnit,

  [IMPERIAL]: {
    isProviderPreferred: true,
    unit: "lb",
    unitLong: "pounds",
    unitTranslationIds: ["P_DMG012", "P_DMG005"],
    conversionFactor: 1.0
  } as AtomicUnit
}

/**
 * A placeholder function (currently commented out) that appears 
 * to be for converting values between different systems of measure.
 */
// export function convertUnitTo (values: NumberAndUnitType[], dim: Dimension, fromSystem: SystemOfMeasure, toSystem: SystemOfMeasure ) {
//   let total = 0;
//   const fromUnits = (Array.isArray(dim[fromSystem]) ? [] values.map(v => {
//     if (Array.isArray(dim[fromSystem])
//   })
//   values.forEach(v => {
//     const unitDefIndex = dim[fromSystem].unit
//   })
// }

export const KNOWN_MEASUREMENT_TYPES = {
  length: Length,
  weight: Weight
};