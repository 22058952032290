export const LoadingPage = () => {
  
  return(
    <div
      className="centered-flex-container main-columm force-full-height"
      style={{ justifyContent: "center" }}
    >
      <h3 className="text-center text-muted">Loading...</h3>
      <div><div className="loader-dots-fade"/></div>
    </div>
  );
}
