import React from "react";
import PropTypes from "prop-types";

const Note = ({ note }) => (
  <div className="highlight-box">
    <div className="note">
      <em>
        <strong>Note: </strong>
        {note.map((part, index) => (
          <React.Fragment key={index}>
            {part.text}
            {part.highlight && <kbd>{part.highlight}</kbd>}
          </React.Fragment>
        ))}
      </em>
    </div>
  </div>
);

Note.propTypes = {
  note: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      highlight: PropTypes.string,
    })
  ).isRequired,
};

export default Note;
