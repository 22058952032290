
/**
 * A simple coordinate pair, usually representing a location in screenspace.
 */
export type PointXY = [number, number];

export interface AnatomicalRegion {
  value?: string;
  label?: string;
  labelTranslationId?: string;
  viewId?: string;
  /**
   * The region's list position. This is assigned dynamically and does not exist
   * in static region definitions (since they have implicit order)
   */
  index?: number | undefined;
  highlightRegion?: string | string[] | null;
  clickableRegion?: DrawableShape | null;
  lineAnchor?: PointXY | {m: PointXY | null, f: PointXY | null} | null;
  shape?: RegionShape;
  disabled?: boolean;
  onlyFemale?: boolean;
}

export interface AnatomicalView {
  isLeaf: boolean;
  /**
   * Whether the view automatically contains an additional choice representing
   * the view itself, e.g. when viewing the head, an option for "head overall".
   * Defaults to true when absent.
   */
  canSelectEntire?: boolean;
  /**
   * How the "legend" list of choices should be arranged within the interface.
   * Defaults to 'right stack'.
   */
  type?: 'right stack' | 'bottom cards';
  /**
   * The URL to the image to be used. Note that when the application is set to
   * use DynamicSVGs, this may use an unusual root. (As of this writing, it must
   * be relative to and a child of the AnatomicalModel directory.)
   */
  imgUrl?: string;
  /**
   * The list of choices that can be selected within this region, complete with
   * their visualization, answer, and subview-linking config.
   */
  choices: AnatomicalRegion[];
  /**
   * Sentinel value used when a view ID cannot be found in a model.
   */
  notFound?: boolean;
  /**
   * Whether this view should be used as the root of the model.
   */
  isRoot?: boolean;

  /**
   * An SVG element to display when this region has "select all" enabled.
   */
  allHighlightRegionId?: string | string[];
}

export type AnatomicalModel = Record<string, AnatomicalView>;

export enum RegionShape {
  Rectangle = "rect",
  Polygon = "poly",
  Circle = "circ",
  None = "NONE",
};
export interface DrawableRectangle {
  shape: RegionShape.Rectangle;
  anchor: PointXY;
  size: [number, number]; // technically equiv to PointXY but semantically diff!

  center: never;
  radius: never;
  points: never;
}
export interface DrawableCircle {
  shape: RegionShape.Circle;
  center: PointXY;
  radius: number;

  anchor: never;
  size: never;
  points: never;
}
export interface DrawablePolygon {
  shape: RegionShape.Polygon;
  points: PointXY[];
  asCubicBezier?: boolean;

  anchor: never;
  size: never;
  center: never;
  radius: never;
}
export interface DrawableNothing {
  shape: RegionShape.None;

  anchor: never;
  size: never;
  center: never;
  radius: never;
  points: never;
}
export type DrawableShape = DrawableCircle | DrawablePolygon | DrawableRectangle | DrawableNothing;
export type DrawableAnatomicalRegion = AnatomicalRegion & DrawableShape;
export type ClickedRegion = {
  id: string;
  value: string;
  weight: number;
}

export enum ClickResolutionMethod {
  Closest = "closest to region center",
  DefinitionOrder = "first defined region",
  SelectAll = "activate all",
};

export enum SVGRegionSelectorType {
  GroupDatasetMatchesRegionName = 1,
  GroupIdMatchesRegionHighlightId = 2
}

export function getRootViewOfModel (m : AnatomicalModel) : string {
  const list = Object.keys(m);
  const optInRoot = list.find(v => m[v].isRoot);
  return optInRoot ?? list[0];
}

/**
 * Find the center of a shape. For circles and rectangles this is exact, but for
 * polygons we weight on the vertices rather than center-of-mass.
 * @param region The shape in question
 * @returns A point duple for the center.
 */
export function findRoughCenter (region: DrawableShape) : PointXY {
  switch (region.shape) {
    case (RegionShape.Circle):
      return region.center;
    case (RegionShape.Rectangle):
      return [region.anchor[0] + region.size[0]/2, region.anchor[1] + region.size[1]/2];
    case (RegionShape.Polygon):
      // For polygons we find the mean all of the points. This works decently
      // for convex shapes whose points are evenly distributed radially, but
      // starts to get funky for particularly concave/complex shapes or ones
      // whose border detail level varies a lot. Still I don't think we need to
      // bring weight vectors or hulls into this; you SHOULD be setting the
      // lineAnchor of a region when you want more control!
      let sumX = 0;
      let sumY = 0
      let minY = Number.MAX_SAFE_INTEGER;
      let maxY = Number.MIN_SAFE_INTEGER;
      for (let [px, py] of region.points) {
        sumX += px;
        sumY += py;
        if (py > maxY) {
          maxY = py;
        }
        if (py < minY) {
          minY = py;
        }
      }
      return [sumX / region.points.length, sumY / region.points.length];
    default:
      return [0, 0];
  }
}

/**
 * Construct a linear list of the views of the model's view-tree by walking it
 * in depth-first order.
 *
 * @param model The model in question, used for retrieving child views
 * @param view The view to start walking from
 * @param seen A set of views already seen, for use in the recursive calls
 * @returns List of the view id strings in their depth-first walk order
 */
export function depthFirstViewsOf(
  model: AnatomicalModel,
  view: AnatomicalView,
  seen = new Set<string>()
): string[] {
  if (!view) return [];
  if (!Array.isArray(view.choices)) return [];
  return view.choices
    .filter((c) : c is AnatomicalRegion & {viewId: string} => (typeof c.viewId == 'string') && !seen.has(c.viewId))
    .map(c => {
      seen.add(c.viewId);
      return [c.viewId, ...depthFirstViewsOf(model, model[c.viewId as string], seen)];
    }).flat(1);
}

/**
 * Determine if a click is within a particular region, and if so, return a
 * comparable "weight" value representing how centered or relevant it is.
 */
export function clickIsInside(
  region : AnatomicalRegion & (DrawableShape | {clickableRegion: DrawableShape}),
  x : number,
  y: number
) : false | number {
  const MIN = 0.1;
  // possible alternative method -- this would probably work best if we don't iterate over regions
  // and just have one master findTarget method, but requires regions to always be drawn on SVG
  // but rendered invisibly (this would honestly be a better approach for simplicity though!)
  // const hits = svgRef.current.getIntersectionList(SVGRect(rawX - 1, rawY - 1, 2, 2), null);

  // regions may define a clickShape object to define their *clickable* region as opposed to
  // their visualized region; this object should have all the same shape types as the outer region
  const eventRegion : DrawableShape = region.clickableRegion ? region.clickableRegion : region as DrawableShape;
  switch (eventRegion.shape) {
    case RegionShape.Circle:
      // for a circle, weight=1 is at the circle's center and weight=MIN is just
      // adjacent to the boundary
      const distance = Math.sqrt(
        (x - eventRegion.center[0]) ** 2 + (y - eventRegion.center[1]) ** 2
      );
      if (distance > eventRegion.radius) {
        return false;
      } else {
        return Math.max(1 - distance / eventRegion.radius, MIN);
      }
    case RegionShape.Rectangle:
      // for a rectangle, weight=1 is at the rectangle's center and weight=MIN
      // is just adjacent to the boundary
      const amounts = [x - eventRegion.anchor[0], y - eventRegion.anchor[1]];
      if (amounts.every((a, i) => a > 0 && a < eventRegion.size[i])) {
        return Math.max(
          1 -
            2 *
              Math.max(
                Math.abs(0.5 - amounts[0] / eventRegion.size[0]),
                Math.abs(0.5 - amounts[1] / eventRegion.size[1])
              ),
          MIN
        );
      } else {
        return false;
      }
    case RegionShape.None:
      // you can never click a None region
      return false;
    case RegionShape.Polygon:
      let sumX = 0;
      let sumY = 0;
      let minY = Number.MAX_SAFE_INTEGER;
      let maxY = Number.MIN_SAFE_INTEGER;
      for (let [px, py] of eventRegion.points) {
        sumX += px;
        sumY += py;
        if (py > maxY) {
          maxY = py;
        }
        if (py < minY) {
          minY = py;
        }
      }
      // const weightedCenter = findRoughCenter(eventRegion);
      const edges = eventRegion.points.map(([x1, y1], i, arr) => {
        const x2 = arr[(i + 1) % arr.length][0];
        const y2 = arr[(i + 1) % arr.length][1];
        if (x2 > x) {
          return [x1, y1, x2, y2];
        } else {
          return [x2, y2, x1, y1];
        }
      });
      const vertIntercepts = edges.map(([x1, y1, x2, y2]) => {
        if (x1 <= x && x <= x2) {
          return ((x - x1) / (x2 - x1)) * (y2 - y1) + y1;
        }
        return null;
      });
      const edgeCrossings = vertIntercepts.filter(
        (v) => v !== null && Number.isFinite(v) && v > y
      );
      if (edgeCrossings.length % 2 === 1) {
        // return Math.max(1/Math.sqrt((x - weightedCenter[0])**2 + (y - weightedCenter[1])**2), MIN);
        let closestDist = Number.MAX_SAFE_INTEGER;
        for (let crossing of vertIntercepts) {
          if (
            crossing !== null &&
            Number.isFinite(crossing) &&
            Math.abs(crossing - y) < closestDist
          ) {
            closestDist = Math.abs(crossing - y);
          }
        }
        return Math.max((2 * closestDist) / (maxY - minY), MIN);
      }
      return false;
    default:
      throw new Error(
        "Unknown region shape " +
          (eventRegion as any)?.shape +
          " in region " +
          region.value
      );
  }
}

export const DEFAULT_RESOLUTION_METHOD = ClickResolutionMethod.Closest;
export const applyResolutionMethod = (viableRegions: ClickedRegion[], methodOverride?: ClickResolutionMethod): ClickedRegion | null => {
  if (!Array.isArray(viableRegions) || viableRegions.length === 0) {
    console.warn(
      `Received empty or non-array viable region list`,
      viableRegions
    );
    return null;
  }
  const resolutionMethod = methodOverride ?? DEFAULT_RESOLUTION_METHOD;
  switch (resolutionMethod) {
    case ClickResolutionMethod.Closest:
      return viableRegions.reduce(
          (prev, current) => (prev.weight > current.weight ? prev : current),
          { id: "N/A", weight: -1, value: "N/A" }
        );
    case ClickResolutionMethod.DefinitionOrder:
      return viableRegions[0]; // this assumes order has been preserved, which may not be guaranteed...

    // We no longer support multiple regions being clicked at the same time as
    // it make the code super messy!
    case ClickResolutionMethod.SelectAll:
    default:
      throw new Error("Unknown or unsupported resolution method " + resolutionMethod);
  }
}