import { DisplayableEntity } from "./formula";
import { QuestionDefinition } from "./questions";

export enum PageType_OLD {
  Empty = "EMPTY",
  QuestionPage = "question",
  QuestionGroup = "question-group",
  QuestionTable = "question-table",
  SectionIntro = "section intro",
  StaticContent = "static content",
  // DemographicData = "demographics",
  // EncouragementMessage = "encouragement",
  SubmitPage = "submit",
  ReviewReport = "review",
}

export enum PageType {
  Loading = "loading",
  Error = "error",
  Debug = "debug",
  Title = "title",
  StudyInvitation = "study_invitation",
  KioskStart = "kiosk_start",
  KioskConsent = "kiosk_consent",
  LanguageSelector = "language-selector",
  Review = "review",
  Success = "success",
  Question = "question",
  SaveWaypoint = "save",
  // ModalQuestion = "question-modal",
  HybridQuestionSummary = "question-hybrid-summary",
  UniqueQuestion = "question-unique",
}

export interface AbstractPage extends DisplayableEntity {
  type: PageType;
  /**
   * We don't really use this yet, but want to type it so it can be improvised
   * as needed. Pages actually have no obvious human-readable identifiers. We
   * can give their index position, but that's not stable as the spec changes,
   * and in some cases we may be dynamically changing indices (rare, but poss).
   */
  id?: string;
  /**
   * The key of a question that is the anchor of a loop containing this page.
   */
  loopId?: string;
  insetLoop?: boolean;

  /**
   * This is only present on question-holding page types but we often check
   * for it generically, so it's useful to have on the abstract type.
   */
  questions?: string[];
}
export interface TitlePage extends AbstractPage {
  type: PageType.Title | PageType.KioskConsent | PageType.KioskStart | PageType.StudyInvitation;
  title: string;
  titleTranslationId?: string;
  art?: string;
  description?: string;
  descriptionTranslationId?: string;
  textChildTranslationId?: string;
  callToAction?: string;
  callToActionTranslationId?: string;
  actOnCTA?: () => void;
  carousel?: [number, number];
}
export interface QuestionPage extends AbstractPage {
  type: PageType.Question;
  questions: string[];
}
// export interface ModalQuestionPage extends AbstractPage {
//   type: PageType.ModalQuestion;
//   questions: string[];
// }
export interface UniqueQuestionPage extends AbstractPage {
  type: PageType.UniqueQuestion;
  uniqueType: string;
  questions: string[];
}
export interface HybridQuestionSummaryPage extends AbstractPage {
  type: PageType.HybridQuestionSummary;
  questions: string[];
}
export interface SuccessPage extends AbstractPage {
  type: PageType.Success;
  title: string;
  titleTranslationId?: string;
  description: string;
  descriptionTranslationId?: string;
  feedbackPrompt: string;
  feedbackPromptTranslationId?: string;
  feedbackChoices: Array<{
    art: string;
    value: string | number;
    title: string;
    titleTranslationId?: string;
  }>;
}
export interface ReviewPage extends AbstractPage {
  type: PageType.Review;
}
export interface LoadingPage extends AbstractPage {
  type: PageType.Loading;
}
export interface SavePage extends AbstractPage {
  type: PageType.SaveWaypoint;
  isFinal: boolean;
  autoProgress: boolean;
}
export const PAGE_LOADING: LoadingPage = {
  type: PageType.Loading,
};
export interface DebugPage extends AbstractPage {
  type: PageType.Debug;
  debugType?: string;
}
export interface LanguageSelectorPage extends AbstractPage {
  type: PageType.LanguageSelector;
}
export interface ErrorPage extends AbstractPage {
  type: PageType.Error;
  error: any;
  trace?: any;
}

export type Page =
  | TitlePage
  | LanguageSelectorPage
  | QuestionPage
  | HybridQuestionSummaryPage
  | UniqueQuestionPage
  | SuccessPage
  | ReviewPage
  | LoadingPage
  | DebugPage
  | ErrorPage
  | SavePage;

export enum RootPageFlow {
  Simulator = "questionnaire simulator",
  Guest = "unauthenticated",
  Provider = "provider dashboard",
  PatientKiosk = "kiosk mode (ED)",
}
