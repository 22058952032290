import { PropsWithChildren } from "react";
import { getAudioLocationFromId } from "../../../models/audio";
import { TitlePage as TitlePageInterface } from "../../../models/pages";
import { ordinals } from "../../../utils";
import { useAppSelector } from "../../../utils/hooks";
import { CTA } from "../../UI/buttons/CTA";
import { useTranslation } from "react-i18next";

type TitlePageProps = PropsWithChildren<
  TitlePageInterface & { style?: React.CSSProperties }
>;

export const TitlePage = ({
  title,
  description,
  art,
  callToAction,
  actOnCTA,
  style,
  carousel,
  children,
  ...props
}: TitlePageProps) => {
  const { t } = useTranslation();

  function CarouselParts() {
    if (Array.isArray(carousel)) {
      const dots = ordinals(+carousel[1]);
      const c = +carousel[0];
      return (
        <>
          {dots.map((d, i) => (
            <div style={{ padding: 5 }}>{i + 1 === c ? "⦿" : "•"}</div>
          ))}
        </>
      );
    } else {
      return null;
    }
  }

  const activeAudio = useAppSelector((s) => s.audio.activeAudio);
  function audioIsPlaying(part: "title" | "description" | "children" | "CTA") {
    const tid = {
      title: props.titleTranslationId,
      description: props.descriptionTranslationId,
      children: props.textChildTranslationId,
      CTA: props.callToActionTranslationId,
    }[part];
    const location = getAudioLocationFromId(tid ?? "/missing/");
    return !!activeAudio.playing && activeAudio.filename === location.filename;
  }

  return (
    <div
      className="centered-flex-container main-column force-full-height"
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        gap: art ? "0" : "40px",
      }}
    >
      {art ? (
        <img className="title-img" src={art} alt="title illustration" />
      ) : null}

      <div
        className={`title large-title text-center ${
          audioIsPlaying("title") ? "pulsing" : ""
        }`}
        style={art ? {} : { position: "absolute", top: "1.5em" }}
      >
        {t(props.titleTranslationId, title)}
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "10px",
        }}
      >
        <div
          className={`body-small text-center ${
            audioIsPlaying("description") ? "pulsing" : ""
          }`}
        >
          {t(props.descriptionTranslationId, description)}
        </div>

        {children ? (
          <p
            className={`body-small text-center ${
              audioIsPlaying("children") ? "pulsing" : ""
            }`}
          >
            {children}
          </p>
        ) : null}
      </div>

      {carousel ? (
        <div
          style={{
            position: "absolute",
            bottom: 20,
            width: "100%",
            display: "flex",
            justifyContent: "center",
            fontSize: "3rem",
            color: "white",
          }}
        >
          <CarouselParts />
        </div>
      ) : null}

      {callToAction ? (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <CTA
            light={style !== "light"}
            className={audioIsPlaying("CTA") ? "pulsing" : ""}
            onClick={actOnCTA}
          >
            {t(props.callToActionTranslationId, callToAction)}
          </CTA>
        </div>
      ) : null}
    </div>
  );
};
