import { ComponentProps, useMemo } from "react";
import { GeneralAnswer, NonconformingValueKind } from "../../../models/answers";
import { isNonconformingSelected, isSelected, selectNonconformingChoice, selectStandardChoice } from "../../../models/choices";
import { ChoiceQuestionDefinition, ResponseChoice } from "../../../models/questions";
import { JSObjectDump } from "../../../utils/UtilComponents";
import { Card } from "../../UI/Card/Card";

function SqrImgCard({
  item,
  handleClick,
  isSelected,
  style = {},
}: ComponentProps<"div"> & {
  item: ResponseChoice,
  handleClick: (i: ResponseChoice) => void,
  isSelected: boolean
}) {
  return (
    <Card
      isSelected={isSelected}
      onClick={() => handleClick(item)}
      art={item.art}
      label={item.label}
      stack={false}
      description={item.description}
      {...item}
    />
  );
}

export default function GridResponse({
  questionDefinition,
  answer,
  updateAnswer,
  hiddenChoices = [],
  showTrackingInfo = false,
  exclusionaryAnswersInline = true,
  displayInfoModal,
}: {
  questionDefinition: ChoiceQuestionDefinition,
  answer: GeneralAnswer,
  updateAnswer: (a: GeneralAnswer) => void,
  hiddenChoices?: number[],
  showTrackingInfo?: boolean,
  exclusionaryAnswersInline?: boolean,
  displayInfoModal?: (choice: ResponseChoice) => void,
}) {
  function toggleChoice(item: ResponseChoice) {
    const updatedAnswerObj = selectStandardChoice(
      item,
      questionDefinition,
      answer,
      // { id: questionDefinition.id }
    );
    updateAnswer(updatedAnswerObj);
  }

  function toggleNonconforming(item: ResponseChoice) {
    const updatedAnswerObj = selectNonconformingChoice(
      item,
      questionDefinition,
      answer
    );
    updateAnswer(updatedAnswerObj);
  }

  const shownChoices = useMemo(() => {
    if (!questionDefinition) {
      return [];
    }
    if (showTrackingInfo) {
      return questionDefinition.choices;
    }
    return questionDefinition.choices.filter((choice, index) => !hiddenChoices.includes(index) || isSelected(index, questionDefinition, answer));
  }, [questionDefinition, hiddenChoices, answer, showTrackingInfo])

  const anyHasDisplayLogic = questionDefinition.choices.some(
    (c) => c.displayWhen || c.skipWhen
  );

  const responses = questionDefinition.choices.map((choice, index) => (
    <>
      {showTrackingInfo ||
      !hiddenChoices.includes(index) ||
      isSelected(index, questionDefinition, answer) ? (
        <SqrImgCard
          key={choice.label}
          item={choice}
          handleClick={toggleChoice}
          handleIconClick={() => displayInfoModal(choice)}
          isSelected={isSelected(index, questionDefinition, answer)}
          style={hiddenChoices.includes(index) ? { opacity: 0.3 } : {}}
        />
      ) : null}
      {showTrackingInfo && anyHasDisplayLogic ? (
        <div>
          {hiddenChoices.includes(index) ? (
            <span className="badge badge-danger mr-1">hidden</span>
          ) : (
            <span className="badge badge-success mr-1">shown</span>
          )}
          {choice.displayWhen ? (
            <>
              <label>#{index} displayWhen: </label>
              <JSObjectDump
                obj={choice.displayWhen}
                style={{ maxHeight: "300px" }}
              />
            </>
          ) : null}
          {choice.skipWhen ? (
            <>
              <label>#{index} skipWhen: </label>
              <JSObjectDump
                obj={choice.skipWhen}
                style={{ maxHeight: "300px" }}
              />
            </>
          ) : null}
          {!choice.displayWhen && !choice.skipWhen ? (
            <em>#{index} (no display logic)</em>
          ) : null}
        </div>
      ) : null}
    </>
  ));

  const nonconformings =
    exclusionaryAnswersInline &&
    Array.isArray(questionDefinition?.nonconformingResponses)
      ? questionDefinition.nonconformingResponses.map((choice, index) => (
          choice.nonconformingKind === NonconformingValueKind.UserDefined ? null :
            <>
              <SqrImgCard
                key={choice.label}
                item={choice}
                handleClick={toggleNonconforming}
                handleIconClick={() => displayInfoModal(choice)}
                isSelected={isNonconformingSelected(index, questionDefinition, answer)}
                style={{}}
              />
              {showTrackingInfo && anyHasDisplayLogic ? (
                <div>
                  <span className="badge badge-dark mr-1">exclusionary</span> #
                  {index}
                </div>
              ) : null}
            </>
        ))
      : null;

  const cardCount = useMemo(() => {
    return shownChoices.length + (Array.isArray(questionDefinition?.nonconformingResponses) ? questionDefinition.nonconformingResponses.length : 0);
  }, [shownChoices, questionDefinition])

  return (
    <div className="main-column">
      <div className={`grid-of-cards count-${cardCount}`}>
        {responses}
        {nonconformings}
      </div>
    </div>
  );
}
