import { useTranslation } from "react-i18next";
import { KeyTranslationIDs } from "../../../models/audio";
import React from "react";

export const UncertainButton = ({label, selected, ...props}: 
  {label?: string, selected?: boolean, props?: React.PropsWithoutRef<'div'>}
) => {
  const {t} = useTranslation();

  return <div className={`uncertain-button ${selected ? "selected" : ""}`} {...props}>
    <img src="/images/response_choice_do_not_know_fm_v1.png" />
    <span>{label ?? t(KeyTranslationIDs.Unsure, "Unsure")}</span>
  </div>
}