import { useEffect, useState } from "react"

export const CountdownTimer = ({timestamp, completionMessage, updateFrequency = 500}) => {
  const debug_initialSec = 60;
  const [millisLeft, setMillisLeft] = useState(debug_initialSec);

  useEffect(() => {
    setTimeout(() => {
      const millis = timestamp - Date.now();
      // const secs = Math.floor(millis / 1000);
      setMillisLeft(millis);
    }, updateFrequency);
  }, [millisLeft])

  return (millisLeft >= 0 ? (<>
    <span style={{fontSize: "60px"}}>{Math.round(millisLeft/1000)}</span>
    <span>seconds</span>
  </>) : <span>{completionMessage}</span>);
}