import { nanoid } from "nanoid";

export enum UserType {
  Provider = "provider",
  Superadmin = "superadmin_literaseed",
  PatientKiosk = "patient_kiosk",
  Unauthenticated = "«unauthenticated»",
  InRetrieval = "«loading»"
}

export interface DBUser {
  name: string;
  email: string;
  type: UserType;
  organizationId: string;
}

export interface BaseUser extends DBUser {
  id: string;
  organization?: Organization;
}

export function createGuest (): BaseUser {
  return ({
    id: `CLIENT_${nanoid(14)}`,
    type: UserType.Unauthenticated,
    name: "Guest",
    email: "",
    organizationId: ""
  });
}

export interface DBOrganization {
  name: string;
  contactEmail: string;
  isAdmin: boolean;
  allowsKioskMode: boolean;
  kioskUsers?: string[]
}
export interface Organization extends DBOrganization {
  id: string;
}
