import { useState } from "react";
import { useTranslation } from "react-i18next";
import { SpecialAnswerKeys } from "../../../models/answers";
import { KeyTranslationIDs } from "../../../models/audio";
import { authSlice } from "../../../store/slices/auth";
import { patientFlowSlice } from "../../../store/slices/patient-flow";
import { useAppDispatch, useAppSelector } from "../../../utils/hooks";
import { Card } from "../../UI/Card/Card";
import { CTA } from "../../UI/buttons/CTA";
import { SmallModalDialog } from "../../UI/dialogs/SmallModalDialog";

const TRIPLE_CLICK_TIME_LIMIT = 2000;

export const SuccessPage = ({ userId, currentReport = {}, restartAction }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [feedback, setFeedback] = useState();
  const feedbackHandler = (value) => {
    setFeedback(value);
  };

  const answerNumber = useAppSelector((s) => s.patientFlow.answerUpdateNumber);
  const saveNumber = useAppSelector((s) => s.patientFlow.answerSaveNumber);
  const reportID = useAppSelector(
    (s) => s.patientFlow.answers.entities[SpecialAnswerKeys.GeneratedReportID]
  );
  const [showProviderDialog, setShowProviderDialog] = useState(false);
  function returnToDashboard() {
    dispatch(authSlice.actions.logout({ reload: true }));
  }

  const [providerButtonClicks, setProviderButtonClicks] = useState([]);
  function showProviderOnTripleClick () {
    const now = Date.now();
    const recentClicks = providerButtonClicks.filter(ts => ts > (now - TRIPLE_CLICK_TIME_LIMIT));
    if (recentClicks.length === 2) {
      // we check length 2 because we haven't added the new one yet
      setShowProviderDialog(true);
      setProviderButtonClicks([])
    } else {
      setProviderButtonClicks([...recentClicks, now]);
    }
  }

  function doRestart() {
    let runDispatch = true;
    if (typeof restartAction === "function") {
      runDispatch = restartAction();
    }
    if (runDispatch) {
      dispatch(
        patientFlowSlice.actions.clearAll({
          isEarly: false,
          reason: "complete",
        })
      );
    }
  }

  const [wasCopied, setWasCopied] = useState(false);
  async function copyReportID() {
    if (reportID?.value?.value) {
      await navigator.clipboard.writeText(reportID?.value?.value);
      setWasCopied(true);
    }
  }

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <img className="title-img" src="/images/Success_Page_2.png" />
        <h1 className="h1 text-center" style={{ fontWeight: "bold" }}>
          {t(KeyTranslationIDs.Success_Title, "Success")}
        </h1>
        <p className="text-center">
          {t(
            KeyTranslationIDs.Success_Description,
            `Thank you for taking part in this research study. Please hand the
          device back. If you feel more sick, please let a nurse know.`
          )}
        </p>
        <br />
      </div>
      <div
        style={{
          width: "100vw",
          /* position: "absolute", bottom: 0, , height: "", */ background:
            "white",
          borderRadius: "10px",
        }}
      >
        {feedback ? (
          <>
            <p
              className="h3 text-center p-4"
              onDoubleClick={() => feedbackHandler(null)}
            >
              {t(
                KeyTranslationIDs.Success_FeedbackCompletion,
                "Thanks for your feedback!"
              )}
            </p>
          </>
        ) : (
          <>
            <p className="h3 text-center p-4">
              {t(
                KeyTranslationIDs.Success_FeedbackPrompt,
                "How would you rate your experience?"
              )}
            </p>
            <div className="centered-flex-container main-column feedback">
              <div className="grid-of-cards" style={{ display: "inline-flex" }}>
                <Card
                  style={{ background: "var(--evergreen)" }}
                  onClick={() => feedbackHandler("Satisfied")}
                  art="/images/emotion_big_smile_fm.png"
                  label="Satisfied"
                  labelTranslationId={
                    KeyTranslationIDs.Success_FeedbackSatisfied
                  }
                />
                <Card
                  style={{ background: "rgb(32, 213, 236)" }}
                  onClick={() => feedbackHandler("Neutral")}
                  art="/images/emotion_neutral_fm.png"
                  label="Neutral"
                  labelTranslationId={KeyTranslationIDs.Success_FeedbackNeutral}
                />
                <Card
                  style={{ background: "rgb(230, 121, 98)" }}
                  onClick={() => feedbackHandler("Dissatisfied")}
                  art="/images/emotion_frown_fm.png"
                  label="Dissastisfied"
                  labelTranslationId={
                    KeyTranslationIDs.Success_FeedbackDissatisfied
                  }
                />
              </div>
            </div>
          </>
        )}
      </div>
      <div>
        <CTA
          label={`Provider: Finish${providerButtonClicks.length > 0 ? " (tap 3x)" : ""}`}
          light={true}
          onClick={showProviderOnTripleClick}
          style={{ width: 400, margin: "3em auto", display: "block" }}
        />
      </div>
      {showProviderDialog ? (
        <SmallModalDialog
          quit={() => {
            setShowProviderDialog(false);
            setWasCopied(false);
          }}
        >
          {saveNumber === -1 ? (
            <em style={{ color: "var(--salmon-red)" }}>
              ERROR: This patient's data has not been saved!
            </em>
          ) : (
            null
            // <em style={{ background: "yellow" }}>
            //   DEBUG: This patient's data was saved on update {saveNumber}/
            //   {answerNumber}.
            // </em>
          )}
          <div class="raised-box" style={{ padding: 5 }} onClick={copyReportID}>
            <h2>{reportID?.value?.value ?? "n/a"}</h2>
          </div>
          {reportID?.value?.value ? (
            <p>
              This is the patient's unique report ID.{" "}
              {wasCopied ? "Copied!" : "Click box to copy to clipboard."}
            </p>
          ) : (
            <p>
              The report ID could not be found. Are you sure the record was
              saved?
            </p>
          )}
          <CTA
            onClick={() => doRestart()}
            light={false}
            style={{ width: "100%" }}
          >
            Restart Kiosk for Next Patient
          </CTA>
          <br />
          <CTA
            onClick={() => returnToDashboard()}
            style={{ backgroundColor: "var(--salmon-red)", width: "100%" }}
          >
            Return to Dashboard
            <br />
            <small>(requires login)</small>
          </CTA>
        </SmallModalDialog>
      ) : null}
    </>
  );
};
