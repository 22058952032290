import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { AnswerSetSubmissionStatus } from "../../../models/answers";
import { answerSetSlice } from "../../../store/slices/answerSets";
import { useAppDispatch, useAppSelector } from "../../../utils/hooks";
import { metricsSlice } from "../../../store/slices/metrics";

export const SaveWaypointPage = ({
  autoProgress = true,
  isFinal = true,
  updateMetrics = true,
  advance,
}: {
  autoProgress?: boolean;
  isFinal?: boolean;
  updateMetrics?: boolean
  advance: () => void;
}) => {
  const dispatch = useAppDispatch();
  const {t} = useTranslation();
  const saveNumber = useAppSelector((s) => s.patientFlow.answerSaveNumber);
  const changeNumber = useAppSelector((s) => s.patientFlow.answerUpdateNumber);
  const needsSave = saveNumber < changeNumber;

  useEffect(() => {
    if (updateMetrics) {
      dispatch(metricsSlice.actions.saveMetricsSession());
    }
  }, [updateMetrics])

  useEffect(() => {
    if (needsSave) {
      dispatch(
        answerSetSlice.actions.saveCurrentAnswerSet(
          isFinal
            ? AnswerSetSubmissionStatus.FlowCompleted
            : AnswerSetSubmissionStatus.WaypointReached
        )
      );
    } else {
      console.warn(`No save needed, last save was on update ${saveNumber}/${changeNumber}`);
      if (autoProgress) {
        advance();
      }
    }
  }, [needsSave]);

  return (
    <div
      className="centered-flex-container main-columm force-full-height"
      style={{ justifyContent: "center" }}
    >
      <h3 className="text-center text-muted">
        {needsSave ? t("INS16", "Saving your answers") : t("R_GEN279", "Done")}
      </h3>
      {needsSave ? (
        <div><div className="loader-dots-fade"/></div>
      ) : (
        <h1>✅</h1>
      )}
    </div>
  );
};
