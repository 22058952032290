import firebase from "firebase";
import { appFirebase } from ".";
import { Answer, AnswerSet } from "../models/answers";
import { DBOrganization, DBUser } from "../models/users";
import { MetricsSession } from "../models/metrics";

export function directConverter<T> (collectionPath: string) {
  const converter = ({
    toFirestore: (t: T) => t,
    fromFirestore: (snap: firebase.firestore.DocumentSnapshot) => snap.data() as T
  }) as firebase.firestore.FirestoreDataConverter<T>;
  return appFirebase.db.collection(collectionPath).withConverter(converter);
}

export const firestoreCollections = {
  users: directConverter<DBUser>('/users'),
  organizations: directConverter<DBOrganization>('/organizations'),
  metrics: directConverter<MetricsSession>('/session-metrics'),
  answerSets: appFirebase.db.collection('/answer-sets').withConverter({
    toFirestore: (a: AnswerSet) => a,
    fromFirestore: (snap: firebase.firestore.DocumentSnapshot<AnswerSet>) => {
      const as = snap.data();
      if (!as) return as;
      Object.entries(as.answers).forEach(([ak, av]: [string, Answer<any>]) => {
        if (av.value?.value?.toMillis) {
          as.answers[ak].value.value = as.answers[ak].value.value.toMillis();
        }
      });
      return as;
    }
  })
}