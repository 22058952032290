import QuestionPrompt from "./QuestionPrompt";
import QuestionResponse from "./QuestionResponse";

export default function SingleQuestion({
  page,
  subpage,
  question,
  answer,
  answerId,
  isActive,
  submitAnswerFor,
  complete,
  goBack,
  containerRef,
  keywordAction,
  displayInfoModal,
  hiddenChoices,
  textOverride,
  topPadding = true,
  contextualResponse,
  showTrackingInfo,
  containerStyles,
  ...addlProps
}) {

  function updateAnswer(newAnswer) {
    submitAnswerFor(newAnswer, answerId ?? question.id);
  }

  if (question) {
    return (
      <div
        /* note that this is normally 100vh tall but gets overridden when inside a modal, see CSS */
        className={`centered-flex-container question-with-inner-scroll ${
          topPadding && question.type != "anatomical area"
            ? "pad-for-header"
            : "pad-anatomical"
        }`}
        style={{
          opacity: isActive ? 1.0 : 0.9,
          transition: "opacity 0.5s linear",
          ...containerStyles,
        }}
      >
        <QuestionPrompt
          text={textOverride?.text ?? question.text}
          subtext={textOverride?.subtext ?? question.subtext}
          id={answerId ?? question.id}
          keywordList={question.keywordList}
          keywordAction={keywordAction}
          {...question}
          contextualResponse={contextualResponse}
        />
        <div className="question-scroller" style={{}}>
          <QuestionResponse
            key={`input:${answerId}`}
            questionDefinition={question}
            answer={answer}
            complete={complete}
            goBack={goBack}
            updateAnswer={updateAnswer}
            subpage={subpage}
            hiddenChoices={hiddenChoices}
            subtextOverrides={textOverride?.responseSubtexts}
            showTrackingInfo={showTrackingInfo}
            displayInfoModal={displayInfoModal}
          />
        </div>
      </div>
    );
  } else {
    return (
      <div className="centered-flex-container pad-for-header">
        <em className="text-muted">Question {answerId} not found.</em>
      </div>
    );
  }
}
