import { Switch } from "@material-ui/core";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import {
  AssumedV2Support,
  DevAssignable,
  generateQuestionnaireKind,
  ScreenerNames,
} from "../constants/screenings";
import {
  generateFakeAnswers,
  requestQuestionnaire,
} from "../store/slices/questionnaire-old.js";
import {
  queryBoolean,
  useAppDispatch,
  useAppSelector,
  useBooleanState,
  useKeyboardEvents,
  useQuery,
} from "../utils/hooks";
import { RedesignedQuestionnaire } from "./Questionnaire/QuestionnaireFlow";
import { SimulatorSettingsPanel } from "./SettingsPanel";

import { Page, PageType } from "../models/pages";
import { simulatorSlice } from "../store/slices/simulator";
import Note from "./Questionnaire/Partials/Note";

export const QuestionnaireManager = () => {
  const dispatch = useAppDispatch();

  // todo: query items can have dedicated file location
  const query = useQuery();

  const simulateFullProvider = useAppSelector(
    (s) => s.simulator.simulateFullProvider
  );
  const simulateKiosk = useAppSelector((s) => s.simulator.simulateKiosk);
  const simulatorActive = useAppSelector((s) => s.simulator.isActive);
  const language = useAppSelector((s) => s.simulator.language);

  useEffect(() => {
    if (!simulatorActive) {
      dispatch(simulatorSlice.actions.activate);
    }
  }, [simulatorActive]);

  // todo: move these to a separate location where user displayed text is handled
  const kioskInjector = (pageArray: Page[]): Page[] => {
    const addedLangAndBody: Page[] = [
      { type: PageType.LanguageSelector },
      {
        type: PageType.KioskConsent,
        title: "Study Invitation",
        titleTranslationId: "",
        description:
          "",
        descriptionTranslationId: "",
        textChildTranslationId: "",
        callToAction: "",
        callToActionTranslationId: "",
      },
      {
        type: PageType.KioskConsent,
        title: "Patient Consent",
        titleTranslationId: "I_GEN042",
        description:
          "I know I'm in a research study and I agree to take part. I've told the Valleywise Health research team that I understand and want to be in the study.",
        descriptionTranslationId: "I_GEN043",
        textChildTranslationId: "P_GEN020",
        callToAction: "I Agree",
        callToActionTranslationId: "R_GEN211",
      },
      ...pageArray,
    ];
    if (simulateFullProvider) {
      return [
        {
          type: PageType.Debug,
          debugType: "login",
          style: "cool",
        },
        {
          type: PageType.Debug,
          debugType: "dashboard",
          style: "cool",
        },
        {
          type: PageType.KioskStart,
          title: "Start the LiteraSeed Questionnaire",
          style: "cool",
          description:
            "Click the button and pass the device to the patient. After they have completed it, they will be instructed to return the device to you.",
          art: "/images/vaccination.png",
        },
        ...addedLangAndBody,
        {
          type: PageType.Review,
          audience: "provider",
          reportType: "ED phase 1 summary",
        },
      ];
    }
    return addedLangAndBody;
  };

  function loadQuestionnaire(t, lg = language) {
    setLoading(true);
    setError(null);

    return dispatch(
      requestQuestionnaire({
        type: t,
        locale: { language: lg },
        transformers: simulateKiosk ? { pages: kioskInjector } : {},
      })
    ).then((loadResult) => {
      setLoading(false);
      if (loadResult.meta.requestStatus !== "fulfilled") {
        const errorMessage =
          loadResult.error?.message || "Failed to load the questionnaire.";
        setError(errorMessage);
        console.error({ result: loadResult });
        return false;
      } else {
        // successful load, don't do anything else
        return true;
      }
    });
  }

  //todo: can these be moved out to their own file location?
  const [chosenType, setChosenType] = useState(null);
  const [chosenKind, setChosenKind] = useState(null);
  const [showQuestionnaire, setShowQuestionnaire] = useState(false);
  useKeyboardEvents(["F4", () => setShowQuestionnaire(false)]);

  const available = DevAssignable.slice().sort((a, b) => a.localeCompare(b));
  const [showOutdatedTypes, setShowOutdatedTypes] = useState(false);

  const [showAuditor, setShowAuditor] = useState(false);
  function audit(): void {
    // TODO
    setShowAuditor(true);
  }

  const loadedQuestionnaires = useAppSelector(
    (s) => s.questionnaire.questionnaires.entities
  );
  const loadedIds = useAppSelector((s) => s.questionnaire.questionnaires.ids);
  const loadedByType = useMemo(() => {
    if (!loadedIds) {
      return {};
    }
    const out = {};
    for (let t of DevAssignable) {
      const found = [];
      for (let kind of loadedIds.filter((id) => id.startsWith(t))) {
        if (kind in loadedQuestionnaires) {
          found.push(loadedQuestionnaires[kind]);
        } else {
          console.error(`${kind} not found in entities list!`);
        }
      }
      out[t] = found;
    }
    return out;
  }, [loadedIds, loadedQuestionnaires]);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  function returnToOptions() {
    /* note that this does NOT do anything to the questionnaire state, if we
       start leveraging this quit method more, we may want to have some sort
       of cleanup process here... */
    setShowQuestionnaire(false);
  }

  // todo: move out into a "debugging tools" module
  function generateAnswers() {
    dispatch(generateFakeAnswers(chosenKind));
  }

  const currentIsLoaded = useMemo(() => {
    return chosenKind && !!loadedIds?.includes(chosenKind);
  }, [chosenKind, loadedIds]);

  useEffect(() => {
    console.warn("attempting autoload");
    const autoload = queryBoolean(query, "autoload", false);
    if (autoload) {
      let screener;
      for (let [k, v] of Array.from(query.entries())) {
        if (
          k.toLocaleLowerCase() === "type" ||
          k.toLocaleLowerCase() === "screener"
        ) {
          screener = v.toLocaleLowerCase();
          break;
        }
      }
      if (screener) {
        if (DevAssignable.includes(screener)) {
          setChosenType(screener);
          setChosenKind(
            generateQuestionnaireKind({ type: screener, locale: { language } })
          );
          loadQuestionnaire(screener, language).then((success) => {
            if (success) {
              setShowQuestionnaire(true);
            }
          });
        }
      }
    }
  }, []);

  const { value: isSidebarOpen, toggle: toggleSidebar } =
    useBooleanState(false);

  return (
    <>
      {showQuestionnaire ? (
        <RedesignedQuestionnaire
          reportType={chosenType}
          quitFn={returnToOptions}
        />
      ) : (
        <div className="dashboard-container">
          <button className="toggle-button" onClick={toggleSidebar}>
            ☰
          </button>
          <div className={`sidebar ${isSidebarOpen ? "open" : ""}`}>
            <div className="logo-container">
              <img
                src="/images/LTSD_green_logo.png"
                alt="Company Logo"
                className="logo"
              />
              <br />
              <span style={{ fontWeight: "bold", textTransform: "uppercase" }}>
                Admin Center
              </span>
            </div>

            <nav className="console-navbar">
              <ul>
                <li className="nav-category">Developer Tools</li>
                <li>
                  <a href="#settings">App Settings</a>
                </li>
                <li>
                  <a href="#content-editor">Content Editor</a>
                </li>
                <li>
                  <a href="#integrations">EHR Integrations</a>
                </li>
                <hr className="divider" />

                <li className="nav-category">Monitoring & Analytics</li>
                <li>
                  <a href="#analytics">Analytics Dashboard</a>
                </li>
                <li>
                  <a href="#audit">Audit Logs</a>
                </li>
                <hr className="divider" />

                <li className="nav-category">User & Access Management</li>
                <li>
                  <a href="#users">User Management</a>
                </li>
                <li>
                  <a href="#notifications">Notifications</a>
                </li>
                <hr className="divider" />

                <li className="nav-category">Product Management</li>
                <li>
                  <a href="#deployment">Deployment Center</a>
                </li>
                <li>
                  <a href="#issues">Issue Tracker</a>
                </li>
                <hr className="divider" />

                <li className="nav-category">Quality Assurance</li>
                <li>
                  <a href="#sandbox">Testing Sandbox</a>
                </li>
                <li>
                  <a href="#inventory">Asset Inventory</a>
                </li>
                <li>
                  <a href="#scripts">Automation Scripts</a>
                </li>

                <hr className="divider" />

                <li className="nav-category">Support & Feedback</li>
                <li>
                  <a href="#help">Help & Documentation</a>
                </li>
                <li>
                  <a href="#feedback">Feedback</a>
                </li>
              </ul>
            </nav>
          </div>
          <div className="content">
            <div className="content-wrapper">
              <h1 className="page-title">Settings</h1>
              <p>
                Complete the configuration, load the questionnaire, then proceed
                to launch.
              </p>
              <hr />

              <h2>Configuration</h2>
              {/* ---------------- Configure Questionnaires ---------------- */}
              <SimulatorSettingsPanel />

              <hr />

              {/* ---------------- Load Questionnaires ---------------- */}
              <h2>Select Questionnaire</h2>
              <p>Choose the specific questionnaire you want to launch.</p>
              <label>
                <Switch
                  checked={showOutdatedTypes}
                  onChange={(e, v) => setShowOutdatedTypes(v)}
                />
                Show Prior Types
              </label>

              <div className="overflow-x">
                <table className="questionnaire-table mt-4">
                  <thead>
                    <tr>
                      <th>Type</th>
                      <th>Screener Name</th>
                      <th>Action</th>
                      <th>Available Variants</th>
                    </tr>
                  </thead>
                  <tbody>
                    {available.map((algType) => (
                      <tr
                        key={algType}
                        className={
                          AssumedV2Support.includes(algType)
                            ? "active-questionnaires"
                            : showOutdatedTypes
                            ? "debug-box"
                            : "zz-display-none"
                        }
                      >
                        <td>
                          <span className="badge badge-light">{algType}</span>
                        </td>
                        <td>{ScreenerNames[algType]}</td>
                        <td>
                          <button
                            className="btn btn-sm"
                            onClick={() => loadQuestionnaire(algType)}
                          >
                            Load in {language.toUpperCase()}
                          </button>
                        </td>
                        <td>
                          {loadedByType[algType] && (
                            <ul className="variant-list">
                              {loadedByType[algType]?.map((q) => (
                                <li
                                  key={q.id}
                                  className="select-list-item"
                                  onClick={() => {
                                    setChosenKind(q.id);
                                    setChosenType(algType);
                                  }}
                                  style={{
                                    backgroundColor:
                                      chosenKind === q.id
                                        ? "rgba(127, 255, 212, 0.4)"
                                        : "",
                                  }}
                                >
                                  <span className="badge badge-dark mx-1">
                                    {q.id}
                                  </span>
                                  <span className="badge badge-light mx-1">
                                    schema v{q.schema_version}
                                  </span>
                                  <span className="badge badge-light mx-1">
                                    v{q.version || "0"}
                                  </span>
                                  <ul className="list-questionnaire-details">
                                    <li>
                                      <strong>File Location:</strong>{" "}
                                      <tt>{q.readFrom}</tt>
                                    </li>
                                    <li>
                                      <strong>Loaded:</strong>{" "}
                                      {moment(q.readTime).format("LTS")}
                                    </li>
                                    <li>
                                      <strong>Pages: </strong> {q.pages?.length}
                                    </li>
                                    <li>
                                      <strong>Questions: </strong>{" "}
                                      {Object.values(q.questions ?? {}).length}
                                    </li>
                                  </ul>

                                  {q.base ? (
                                    <span>based on {q.base}</span>
                                  ) : null}
                                  {q.extends ? (
                                    <span>
                                      based on {q.extends.url} as{" "}
                                      {q.extends.role}
                                    </span>
                                  ) : null}
                                </li>
                              ))}
                              {loadedByType[algType]?.length ? null : (
                                <em>None loaded</em>
                              )}
                            </ul>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              {/* ---------------- Buttons to Launch Questionnaire ---------------- */}
              {loading && <p className="loading-spinner">Loading...</p>}
              {chosenKind && currentIsLoaded ? (
                <button className="btn w-100" onClick={() => generateAnswers()}>
                  Generate Random Answers
                </button>
              ) : null}
              <button
                className="btn w-100"
                disabled={!currentIsLoaded}
                onClick={() => setShowQuestionnaire(true)}
              >
                {chosenKind
                  ? currentIsLoaded
                    ? "Launch"
                    : "Your selected type is not loaded"
                  : "First select a type above"}
              </button>
              <button
                className="btn"
                disabled={!currentIsLoaded}
                onClick={audit}
              >
                Run Auditor
              </button>
            </div>
            {/* ---------------- Highlighted Notes & Messages ---------------- */}
            <div className="messages-wrapper">
              {error && (
                <p className="highlight-error-message">
                  <strong>Error:</strong> {error}
                </p>
              )}

              <Note
                note={[
                  {
                    text: "You can return to this screen anytime by pressing ",
                  },
                  { highlight: "F4" },
                  {
                    text: ". However, in some cases, a full reload may still be necessary after a crash.",
                  },
                ]}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};
