import { ordinals } from "../../utils";



export const ProgressDots = ({current, max}: {current: number, max: number}) => {
  const isCapped = max > 5;
  const cappedMax = Math.min(Number.isSafeInteger(max) ? max : 0, 5);
  const numberIterable = ordinals(cappedMax);
  return <div className="progress-dots" title={`${typeof current} ${current} of ${typeof max} ${max}`}>
    {numberIterable.map(n => (
      n === current ? 
        <div key={"pnc" + n} className="dot-current">{n + 1}</div>
      :
        <div key={"pn" + n} className="dot">&nbsp;</div>
    ))}
  </div>
}